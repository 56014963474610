import { useEffect, useMemo, useRef, useState } from 'react';
import { HStack, Skeleton } from '@chakra-ui/react';
import { NumberInput } from 'Atoms/index.ts';
import { Tooltip } from 'Atoms/Tooltip/Tooltip.tsx';
import { Typography } from 'Tokens/index.ts';
import { WarningIcon } from 'Tokens/Icons/Status.tsx';
import { useGetFinancialInputData } from './FinancialInput.hooks.ts';
import { useTranslation } from 'utils/translation/translation.ts';
import { formatNumber } from 'utils/numbers.ts';

export const FinancialInput = ({
  section,
  financialsId,
}: {
  section: string;
  financialsId: string;
}) => {
  const FINANCIAL_SAVE_DELAY_MS = 1000;
  const { t } = useTranslation(['financials']);

  const [financialNumber, setFinancialNumber] = useState<number>(0);
  const [initialLoad, setInitialLoad] = useState(true);
  const [localLatestNumber, setLocalLatestNumber] = useState<number>();
  const [isFocused, setIsFocused] = useState(false);
  const [isPasting, setIsPasting] = useState<number | undefined>(undefined);
  const inputRef = useRef<HTMLInputElement>(null);
  const {
    value,
    errorMessage,
    isDisabled,
    isLocked,
    onChange,
    updateFinancialsLoading,
    loading,
    resetErrorMessage,
  } = useGetFinancialInputData({
    section,
    financialsId,
  });

  useEffect(() => {
    if (errorMessage !== undefined) {
      setFinancialNumber(value);
    }
  }, [errorMessage]);

  useEffect(() => {
    if (initialLoad) {
      if (value !== undefined) {
        setLocalLatestNumber(value);
        setFinancialNumber(value);
        setInitialLoad(false);
      }
    }
  }, [value]);

  useEffect(() => {
    if (financialNumber !== localLatestNumber) {
      const debounceSave = setTimeout(() => {
        setLocalLatestNumber(financialNumber);
        onChange(financialNumber);
      }, FINANCIAL_SAVE_DELAY_MS);

      return () => clearTimeout(debounceSave);
    }
  }, [financialNumber]);

  const tooltipLabel = errorMessage;

  if (isLocked) {
    return <Typography marginRight="15px">{formatNumber(value ?? 0)}</Typography>;
  } else {
    return (
      <Tooltip
        backgroundColor={errorMessage ? 'bg.critical.accent' : undefined}
        placement="top"
        label={tooltipLabel}
        hasArrow
        defaultIsOpen={errorMessage ? true : false}
        closeDelay={errorMessage ? 500 : undefined}
      >
        <Skeleton isLoaded={initialLoad === false || !loading}>
          <HStack width="100%">
            <NumberInput
              ref={inputRef}
              key={`${financialsId}-${section}`}
              id={`${financialsId}-${section}`}
              width="100%"
              value={isFocused ? financialNumber : formatNumber(financialNumber)}
              isInvalid={errorMessage !== undefined}
              isDisabled={isDisabled}
              onClick={() => resetErrorMessage()}
              onChange={(val) => {
                if (
                  financialNumber === 0 &&
                  val.toString().length > 1 &&
                  val.toString().endsWith('0') &&
                  (isPasting === 0 || isPasting === undefined)
                ) {
                  setFinancialNumber(Number(val.toString().slice(0, -1)));
                } else {
                  setFinancialNumber(val);
                }
                setIsPasting(undefined);
              }}
              onFocus={() => {
                setIsFocused(true);
              }}
              onBlur={() => {
                setIsFocused(false);
                if (financialNumber !== localLatestNumber) {
                  onChange(financialNumber);
                }
              }}
              onPaste={() => {
                if (inputRef.current?.selectionStart !== inputRef.current?.selectionEnd) {
                  setIsPasting(-1);
                } else {
                  setIsPasting(inputRef.current?.selectionStart ?? undefined);
                }
              }}
              replaceNanWithZero={true}
              loading={updateFinancialsLoading}
              leftIcon={errorMessage ? <WarningIcon color="text.warning" /> : undefined}
            />
          </HStack>
        </Skeleton>
      </Tooltip>
    );
  }
};
