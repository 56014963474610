import {
  ActivityReport_Arr_Rel_Insert_Input_,
  ActivityReport_Constraint_,
  ActivityReport_Insert_Input_,
  ActivityReport_Update_Column_,
  ActivityResults,
  ActivityTagEnum,
  AssessmentFlags,
  BareFinancials,
  BusinessUnitAssessmentResults,
  BusinessUnitAssessment_Arr_Rel_Insert_Input_,
  BusinessUnitAssessment_Constraint_,
  BusinessUnitAssessment_Insert_Input_,
  BusinessUnitAssessment_Update_Column_,
  BusinessUnitCachedResult,
  CachedResult_,
  CachedResult_Insert_Input_,
  CachedResult_Obj_Rel_Insert_Input_,
  CalculatedActivityResult,
  CompanyAssessment,
  CompanyDetails,
  ObjectiveKeyEnum,
  User,
} from 'models';
import Papa from 'papaparse';
import { getSubsidiaryResults } from './ExcelGenerator/consolidated-financials-excel-generator';
import { getScreenedActivityFinancials, scoreSections } from 'utils/financials';
import { EntityAlignmentStatus } from 'Organisms/AlignmentStatus';
import {
  checkIfAlwaysEnablingActivity,
  defaultFinancials,
  getBusinessUnitAssessmentResult,
  getCompanyAssessmentResult,
} from 'utils/scores/taxonomyScore';
import { cacheResultsOnConflict } from 'Features/Results/Results.hooks';
import { v4 } from 'uuid';
import { getSubstantialContributionProportion } from 'utils/scores/substantialContribution';

type CompanyIndex = {
  name: string;
  index: number;
};

const COMPANY_NOT_ELIGIBLE_ROW_NAME = 'Company non-eligible activities';
const ACTIVITY_NOT_ELIGIBLE_ROW_NAME = 'Non-eligible activities';

function getBusinessUnitName(companyName: string, reportingUnitName: string) {
  return `${companyName} - ${reportingUnitName}`;
}

function getCompanyName(row: any[]) {
  return row[0];
}
function getReportingUnit(row: any[]) {
  return row[2];
}
function getReportingUnitId(row: any[]) {
  return row[3];
}
function getActivity(row: any[]) {
  return row[4];
}
function getRevenue(row: any[]) {
  return row[5];
}
function getCapex(row: any[]) {
  return row[6];
}
function getOpex(row: any[]) {
  return row[7];
}
function getAdaptationCapex(row: any[]) {
  return row[8];
}
function getAdaptationOpex(row: any[]) {
  return row[9];
}

const getCachedResultForActivity = ({
  newFinancials,
  oldCachedResult,
  activities,
}: {
  newFinancials: BareFinancials;
  oldCachedResult: CachedResult_;
  activities: any[];
}): CalculatedActivityResult => {
  const financialsProgress = oldCachedResult.progress?.financials;
  const screeningProgress = oldCachedResult.progress?.screening;
  const possibleSubstantialContributionObjectives =
    oldCachedResult.objectivesState?.possibleSubstantialContributionObjectives;
  const substantialContributionObjectives =
    oldCachedResult.objectivesState?.substantialContributionObjectives;
  const significantHarmObjectives = oldCachedResult.objectivesState?.significantHarmObjectives;
  const isAligned = oldCachedResult.isAligned ?? false;
  const isCompleted = oldCachedResult?.isCompleted ?? false;
  const isAdaptationOnlyActivity =
    possibleSubstantialContributionObjectives.length === 1 &&
    possibleSubstantialContributionObjectives.includes(ObjectiveKeyEnum.adaptation);
  const canBeEnabling = activities
    .find((a) => a.reference === oldCachedResult.activityRef)
    ?.versions.find(
      (v: { versionNumber: number; tagExpression: ActivityTagEnum }) =>
        v.versionNumber === (oldCachedResult?.activityVersionNumber ?? 1)
    )
    ?.tagExpression?.includes(ActivityTagEnum.enabling);
  const isEnabling = oldCachedResult.activityTag === ActivityTagEnum.enabling;
  const isTransitional = oldCachedResult.activityTag === ActivityTagEnum.transitional;

  const isAdaptationOnlySC =
    substantialContributionObjectives.length == 1 &&
    substantialContributionObjectives.includes(ObjectiveKeyEnum.adaptation);

  const financials = newFinancials;
  const hasClimateRiskAssessment = oldCachedResult?.objectivesState?.hasClimateRiskAssessment;

  const isAlwaysEnablingActivity = checkIfAlwaysEnablingActivity(
    oldCachedResult?.activityRef ?? ''
  );

  if (hasClimateRiskAssessment === undefined) {
    throw new Error('hasClimateRiskAssessment is undefined');
  }
  const assessmentFlags: AssessmentFlags = {
    isAdaptationOnlySC,
    isAdaptationOnlyActivity,
    isAligned,
    isCompleted,
    isEnabling,
    isTransitional,
    canBeEnabling,
    hasClimateRiskAssessment,
    isAlwaysEnablingActivity,
  };

  const status = (oldCachedResult.status ?? 'todo') as EntityAlignmentStatus;

  const screenedFinancials = getScreenedActivityFinancials(financials, assessmentFlags);

  const score: CalculatedActivityResult['score'] = scoreSections.reduce(
    (agg, s) => ({
      ...agg,
      [s]: {
        total: 100,
        aligned: isAligned && isCompleted ? 100 : 0,
        inProgress: isAligned && !isCompleted ? 100 : 0,
        eligible: !isAdaptationOnlyActivity || isEnabling || isAlwaysEnablingActivity ? 100 : 0, // TODO: This might be wrong, check if adpataioncapex should be included
        enabling: isEnabling ? 100 : 0, // TODO: This might be wrong, check if adpataioncapex should be included
        transitional: isTransitional ? 100 : 0,
      },
    }),
    {} as CalculatedActivityResult['score']
  );

  return {
    activityRef: oldCachedResult.activityRef ?? '',
    activityTag: (oldCachedResult.activityTag ?? 'GREEN') as ActivityTagEnum,
    activityVersionNumber: oldCachedResult.activityVersionNumber ?? 1,
    progress: {
      financials: financialsProgress,
      screening: screeningProgress,
    },
    financials: screenedFinancials,
    score,
    isAligned,
    isCompleted,
    objectivesState: {
      possibleSubstantialContributionObjectives: possibleSubstantialContributionObjectives,
      significantHarmObjectives: significantHarmObjectives,
      substantialContributionObjectives: substantialContributionObjectives,
      hasClimateRiskAssessment: hasClimateRiskAssessment,
    },
    status,
  };
};

function extractCompanyRows(company: CompanyIndex, companyIndexMap: CompanyIndex[], data: any[]) {
  const isLastCompany = company === companyIndexMap[companyIndexMap.length - 1];
  const startIndex = company.index + 1;
  let endIndex;

  if (isLastCompany) {
    endIndex = data.length - 1;
  } else {
    endIndex = companyIndexMap[companyIndexMap.indexOf(company) + 1].index - 1;
  }

  return data.slice(startIndex, endIndex).map((row: any[]) => ({
    reportingUnitName: getReportingUnit(row),
    reportingUnitId: getReportingUnitId(row),
    activity: getActivity(row),
    financials: {
      revenue: getRevenue(row),
      capex: getCapex(row),
      opex: getOpex(row),
      adaptationCapex: getAdaptationCapex(row),
      adaptationOpex: getAdaptationOpex(row),
    },
  }));
}

function extractReportingUnits(
  companyRows: any[],
  realCompanyData: any,
  activityNameMap: { [key: string]: string }
) {
  const uniqueReportingUnits = Array.from(
    new Map(
      companyRows
        .filter((row) => row.reportingUnitName !== COMPANY_NOT_ELIGIBLE_ROW_NAME)
        .map((row) => [row.reportingUnitId, row])
    ).values()
  );

  return uniqueReportingUnits.map((unit: any) => {
    const rows = companyRows.filter(
      (row: { reportingUnitId: any }) => row.reportingUnitId === unit.reportingUnitId
    );
    const realBusinessUnitResults = realCompanyData?.businessUnitResults.find(
      (buResults: BusinessUnitAssessmentResults) =>
        buResults.businessUnit.id === unit.reportingUnitId
    );

    const nonEligibleFinancials = rows.find(
      (row) => row.activity === ACTIVITY_NOT_ELIGIBLE_ROW_NAME
    )?.financials;

    const activities = rows.filter(
      (row: { activity: any }) => row.activity !== ACTIVITY_NOT_ELIGIBLE_ROW_NAME
    );

    return {
      name: unit.reportingUnitName,
      activities: activities.map((row: { activity: any; financials: any }) => {
        return {
          name: row.activity,
          financials: row.financials,
          cachedResult: realBusinessUnitResults?.activityResults?.find(
            (activityResults: ActivityResults) =>
              activityNameMap[activityResults.activityRef] === row.activity
          )?.cachedResult,
        };
      }),
      cachedResult: realBusinessUnitResults.cachedResult,
      hasGeneralAssessment: realBusinessUnitResults?.hasGeneralAssessment,
      generalAssessment: realBusinessUnitResults?.generalAssessmentResults[0],
      notEligibleFinancials: nonEligibleFinancials,
    };
  });
}

function extractRealCompanyData(company: CompanyIndex, subsidiaryList: any[]) {
  return subsidiaryList.find((subsidiary) => subsidiary.company.name === company.name)
    ?.sharedCompanyAssessment;
}

function isRowUserMade(row: any[]) {
  const hasTotalColumn = row[0] === 'Total';
  const hasReportingUnitColumn = row[2] !== null && row[2] !== undefined && row[2] !== '';
  return !hasTotalColumn && !hasReportingUnitColumn;
}

const removeAllUserMadeRows = (data: any[][]) => {
  return data.filter((row) => !isRowUserMade(row));
};

export async function parseConsolidatedResultsToCached({
  results,
  activities,
  groupCompany,
  user,
  gAssessmentId,
  consolidatedCompanyAssessment,
}: {
  results: Papa.ParseResult<any>;
  activities: any[];
  groupCompany?: CompanyDetails;
  user?: User;
  gAssessmentId?: string;
  consolidatedCompanyAssessment: CompanyAssessment;
}): Promise<{
  bAssessments: BusinessUnitAssessment_Arr_Rel_Insert_Input_;
  cachedResult: CachedResult_Obj_Rel_Insert_Input_;
}> {
  const activityNameMap = activities.reduce(
    (nameMap, activity) => ({ ...nameMap, [activity.reference]: activity.name }),
    {}
  );

  if (!gAssessmentId) throw new Error('gAssessmentId is undefined');

  const groupAssessment = await getSubsidiaryResults({ gAssessmentId });
  if (!groupAssessment) throw new Error('groupAssessment is undefined');
  const subsidiaryList = groupAssessment.subsidiaries;

  //removing the first row as its the header
  const data: any[][] = results.data.slice(1);

  //depending on the csv format, the first column might be empty, so removing it if so to avoid errors
  if (
    data.every(
      (row) =>
        getCompanyName(row) === null ||
        getCompanyName(row) === undefined ||
        getCompanyName(row) === ''
    )
  ) {
    for (let i = 0; i < data.length; i++) {
      data[i].shift();
    }
  }

  const dataWithoutUserMadeRows = removeAllUserMadeRows(data);

  const isAdaptationCapexGreaterThanCapex = dataWithoutUserMadeRows.some(
    (row) => getAdaptationCapex(row) > getCapex(row)
  );
  const isAdaptationOpexGreaterThanOpex = dataWithoutUserMadeRows.some(
    (row) => getAdaptationOpex(row) > getAdaptationOpex(row)
  );

  if (isAdaptationCapexGreaterThanCapex || isAdaptationOpexGreaterThanOpex) {
    throw new Error('Adaptation Capex or Opex cannot be greater than Capex or Opex');
  }

  const companyIndexMap: CompanyIndex[] = dataWithoutUserMadeRows
    .map((row, index) => {
      const name = getCompanyName(row);
      return { name, index };
    })
    .filter((row) => row.name !== 'Total' && row.name !== undefined && row.name !== null);

  const companies = companyIndexMap.map((companyNameIndex) => {
    const companyRows = extractCompanyRows(
      companyNameIndex,
      companyIndexMap,
      dataWithoutUserMadeRows
    );
    const realCompanyData = extractRealCompanyData(companyNameIndex, subsidiaryList);
    const reportingUnits = extractReportingUnits(companyRows, realCompanyData, activityNameMap);
    const companyNonEligibleFinancials = companyRows.find(
      (row) => row.reportingUnitName === COMPANY_NOT_ELIGIBLE_ROW_NAME
    )?.financials;

    return {
      name: companyNameIndex.name,
      reportingUnits: reportingUnits,
      realCompanyData: realCompanyData,
      companyNonEligibleFinancials,
    };
  });

  const consolidatedBusinessUnitAssessmentInserts: BusinessUnitAssessment_Insert_Input_[] =
    companies.flatMap((company) => {
      return company.reportingUnits.map((reportingUnit) => {
        const realCompanyGeneralAsssessment =
          company.realCompanyData.generalBusinessUnitResult[0].generalAssessmentResults[0];
        const companyLevelGeneralAssessmentResult: ActivityReport_Insert_Input_ = {
          activityRef: '0.0',
          activityVersionNumber:
            realCompanyGeneralAsssessment?.cachedResult?.activityVersionNumber ?? 1,
          deletedAt: null,
          cachedResult: {
            data: {
              companyId: groupCompany?.id,
              isDirty: false,
              isAligned: realCompanyGeneralAsssessment?.cachedResult?.isAligned,
              isCompleted: realCompanyGeneralAsssessment?.cachedResult?.isCompleted ?? false,
              score: {},
              objectivesState: realCompanyGeneralAsssessment?.cachedResult?.objectivesState ?? {},
              financials: {
                revenue: {
                  notEligible: reportingUnit.notEligibleFinancials.revenue,
                },
                capex: {
                  notEligible: reportingUnit.notEligibleFinancials.capex,
                },
                opex: {
                  notEligible: reportingUnit.notEligibleFinancials.opex,
                },
              },
              progress: realCompanyGeneralAsssessment?.cachedResult?.isCompleted
                ? { screening: 100, financials: 100 }
                : { screening: 0, financials: 0 },
              id: v4(),
            },
            on_conflict: cacheResultsOnConflict,
          },
        };

        const activityReports: ActivityReport_Arr_Rel_Insert_Input_ = {
          data: reportingUnit.activities.map((activity) => {
            const cachedResultForActivity: CachedResult_Insert_Input_ = {
              ...getCachedResultForActivity({
                newFinancials: activity.financials,
                oldCachedResult: activity.cachedResult,
                activities: activities,
              }),
              id: v4(),
              companyId: groupCompany?.id,
              isDirty: false,
            };
            return {
              activityRef: activity.cachedResult.activityRef,
              activityVersionNumber: activity?.cachedResult?.activityVersionNumber ?? 1,
              deletedAt: null,
              cachedResult: {
                data: cachedResultForActivity,
                on_conflict: cacheResultsOnConflict,
              },
            };
          }),
          on_conflict: {
            constraint:
              ActivityReport_Constraint_.ActivityReportBAssessmentIdActivityRef_614b7372Uniq_,
            update_columns: [
              ActivityReport_Update_Column_.CachedResultId_,
              ActivityReport_Update_Column_.ActivityVersionNumber_,
              ActivityReport_Update_Column_.DeletedAt_,
            ],
          },
        };

        const reportingUnitGeneralAssessmentResult: CachedResult_Insert_Input_ = {
          companyId: groupCompany?.id,
          activityRef: '0.0',
          isDirty: false,
          isAligned: reportingUnit.generalAssessment?.cachedResult?.isAligned,
          isCompleted: reportingUnit.generalAssessment?.cachedResult?.isCompleted ?? false,
          activityVersionNumber:
            reportingUnit.generalAssessment?.cachedResult?.activityVersionNumber ?? 1,
          score: {},
          financials: {
            revenue: {
              notEligible: reportingUnit.notEligibleFinancials.revenue,
            },
            capex: {
              notEligible: reportingUnit.notEligibleFinancials.capex,
            },
            opex: {
              notEligible: reportingUnit.notEligibleFinancials.opex,
            },
          },
          objectivesState: reportingUnit?.generalAssessment?.cachedResult?.objectivesState ?? {},
          progress: reportingUnit.generalAssessment?.cachedResult?.isCompleted
            ? { screening: 100, financials: 100 }
            : { screening: 0, financials: 0 },
          id: v4(),
        };

        if (reportingUnit.hasGeneralAssessment) {
          activityReports.data.push({
            activityRef: '0.0',
            activityVersionNumber: 1,
            deletedAt: null,
            cachedResult: {
              data: {
                ...reportingUnitGeneralAssessmentResult,
              },
              on_conflict: cacheResultsOnConflict,
            },
          });
        } else {
          activityReports.data.push(companyLevelGeneralAssessmentResult);
        }

        const generalAssessmentResult: CachedResult_Insert_Input_ | undefined =
          reportingUnit.hasGeneralAssessment
            ? reportingUnitGeneralAssessmentResult
            : companyLevelGeneralAssessmentResult.cachedResult?.data;
        const businessUnitCachedResult: CachedResult_Insert_Input_ =
          getBusinessUnitAssessmentResult({
            businessUnitFinancials: defaultFinancials,
            generalAssessmentResult: {
              id: 'consolidateGeneralAssessment',
              score: {},
              progress: { screening: 100, financials: 100 },
              financials: generalAssessmentResult?.financials ?? {},
              isCompleted: generalAssessmentResult?.isCompleted ?? false,
              objectivesState: generalAssessmentResult?.objectivesState ?? {},
              isDirty: false,
              isAligned: generalAssessmentResult?.isAligned ?? false,
            },
            hasGeneralAssessment: true,
            activityAssessmentResults: activityReports.data.map((activityReport) => ({
              activityAssessmentId: activityReport.activityRef ?? '',
              activityVersionNumber: activityReport.activityVersionNumber ?? 1,
              activityRef: activityReport.activityRef ?? '',
              cachedResult: activityReport.cachedResult?.data as unknown as CachedResult_,
            })),
          });

        const businessUnit = consolidatedCompanyAssessment?.bAssessments?.find(
          (bAssessment) =>
            bAssessment.businessUnit?.name === getBusinessUnitName(company.name, reportingUnit.name)
        )?.businessUnit;
        if (!!businessUnit) {
          return {
            businessUnitId: businessUnit?.id,
            activityReports: activityReports,
            deletedAt: null,
            hasGeneralAssessment: true,
            cachedResult: {
              data: {
                ...businessUnitCachedResult,
                isDirty: false,
                companyId: groupCompany?.id,
                id: v4(),
              },
              on_conflict: cacheResultsOnConflict,
            },
          };
        }
        return {
          businessUnit: {
            data: {
              name: getBusinessUnitName(company.name, reportingUnit.name),
              contactPersonId: user?.id,
              companyId: groupCompany?.id ?? '',
            },
          },
          activityReports: activityReports,
          hasGeneralAssessment: true,
          deletedAt: null,
          cachedResult: {
            data: {
              ...businessUnitCachedResult,
              isDirty: false,
              companyId: groupCompany?.id,
              id: v4(),
            },
            on_conflict: cacheResultsOnConflict,
          },
        };
      });
    });

  const totalCompanyNotEligibleFinancials = companies.reduce(
    (agg, company) => ({
      revenue: agg.revenue + company.companyNonEligibleFinancials?.revenue,
      capex: agg.capex + company.companyNonEligibleFinancials?.capex,
      opex: agg.opex + company.companyNonEligibleFinancials?.opex,
    }),
    {
      revenue: 0,
      capex: 0,
      opex: 0,
    }
  );

  const companyLevelGeneralAssessmentResult: CachedResult_Insert_Input_ = {
    companyId: groupCompany?.id,
    isDirty: false,
    isAligned: false,
    isCompleted: false,
    score: {},
    objectivesState: {},
    financials: {
      revenue: {
        notEligible: totalCompanyNotEligibleFinancials.revenue,
      },
      capex: {
        notEligible: totalCompanyNotEligibleFinancials.capex,
      },
      opex: {
        notEligible: totalCompanyNotEligibleFinancials.opex,
      },
    },
    progress: { screening: 0, financials: 0 },
    id: v4(),
  };

  consolidatedBusinessUnitAssessmentInserts.push({
    hasGeneralAssessment: true,
    deletedAt: null,
    activityReports: {
      data: [
        {
          activityRef: '0.0',
          activityVersionNumber: 1,
          deletedAt: null,
          cachedResult: {
            data: companyLevelGeneralAssessmentResult,
          },
        },
      ],
      on_conflict: {
        constraint: ActivityReport_Constraint_.ActivityReportBAssessmentIdActivityRef_614b7372Uniq_,
        update_columns: [
          ActivityReport_Update_Column_.CachedResultId_,
          ActivityReport_Update_Column_.DeletedAt_,
        ],
      },
    },
  });

  const businessUnitAssessmentResults = consolidatedBusinessUnitAssessmentInserts.map(
    (bAssessment) => ({
      bAssessmentId: bAssessment.businessUnit?.data?.name ?? '',
      businessUnit: {
        name: bAssessment.businessUnit?.data?.name ?? '',
        id: bAssessment.businessUnit?.data?.name ?? '',
      },
      hasGeneralAssessment: true,
      generalAssessmentResult: {
        activityAssessmentId: `consolidateGeneralAssessment - ${
          bAssessment.businessUnit?.data?.name ?? ''
        }`,
        activityVersionNumber: 1,
        bAssessmentId: bAssessment.businessUnit?.data?.name ?? '',
        cachedResult: bAssessment?.activityReports?.data?.find(
          (activityReport) => activityReport.activityRef === '0.0'
        )?.cachedResult?.data as unknown as CachedResult_,
      },
      cachedResult: bAssessment.cachedResult?.data as unknown as BusinessUnitCachedResult,
      activityResults:
        bAssessment.activityReports?.data.map((activityReport) => ({
          activityVersionNumber: activityReport.activityVersionNumber ?? 1,
          activityAssessmentId: activityReport.activityRef ?? '',
          activityRef: activityReport.activityRef ?? '',
          cachedResult: activityReport.cachedResult?.data as unknown as CachedResult_,
          isMSSGAligned:
            bAssessment.activityReports?.data?.find((aR) => aR.activityRef === '0.0')?.cachedResult
              ?.data?.isAligned ?? false,
        })) ?? [],
    })
  );

  const companyAssessmentCachedResult = getCompanyAssessmentResult({
    companyFinancials: {
      revenue: totalCompanyNotEligibleFinancials.revenue,
      capex: totalCompanyNotEligibleFinancials.capex,
      opex: totalCompanyNotEligibleFinancials.opex,
      adaptationCapex: 0,
      adaptationOpex: 0,
      isEstimate: false,
    },
    generalAssessmentResult: {
      id: 'consolidateGeneralAssessment',
      score: companyLevelGeneralAssessmentResult.score ?? {},
      progress: { screening: 100, financials: 100 },
      financials: companyLevelGeneralAssessmentResult?.financials ?? {},
      isCompleted: companyLevelGeneralAssessmentResult?.isCompleted ?? false,
      isAligned: companyLevelGeneralAssessmentResult?.isAligned ?? false,
      objectivesState: companyLevelGeneralAssessmentResult?.objectivesState ?? {},
    },
    businessUnitAssessmentResults: businessUnitAssessmentResults,
  });

  const substantialContributionProportion = getSubstantialContributionProportion({
    companyAssessmentId: consolidatedCompanyAssessment?.id,
    aggregateId: consolidatedCompanyAssessment?.aggregate.id,
    startDate: consolidatedCompanyAssessment?.startDate,
    endDate: consolidatedCompanyAssessment?.endDate,
    generalAssessmentResult: {
      activityAssessmentId: v4(),
      activityVersionNumber: 1,
      bAssessmentId: v4(),
      cachedResult: {
        id: 'consolidateGeneralAssessment',
        score: companyLevelGeneralAssessmentResult.score ?? {},
        progress: { screening: 100, financials: 100 },
        financials: companyLevelGeneralAssessmentResult?.financials ?? {},
        isCompleted: companyLevelGeneralAssessmentResult?.isCompleted ?? false,
        isAligned: companyLevelGeneralAssessmentResult?.isAligned ?? false,
        objectivesState: companyLevelGeneralAssessmentResult?.objectivesState ?? {},
      },
    },
    businessUnitResults: businessUnitAssessmentResults,
    cachedResult: { ...companyAssessmentCachedResult, id: v4() },
  });

  return {
    bAssessments: {
      data: consolidatedBusinessUnitAssessmentInserts,
      on_conflict: {
        constraint:
          BusinessUnitAssessment_Constraint_.BusinessUnitAssessmentCAssessmentIdBusinessUnitIdN_8db61b88U_,
        update_columns: [
          BusinessUnitAssessment_Update_Column_.CachedResultId_,
          BusinessUnitAssessment_Update_Column_.HasGeneralAssessment_,
          BusinessUnitAssessment_Update_Column_.DeletedAt_,
        ],
      },
    },
    cachedResult: {
      data: {
        ...companyAssessmentCachedResult,
        objectivesState: {
          substantialContributionProportion,
        },
        isDirty: false,
        companyId: groupCompany?.id,
        id: v4(),
      },
      on_conflict: cacheResultsOnConflict,
    },
  };
}
