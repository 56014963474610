import {
  FrequencyEnums,
  TimePeriodsEnums,
  TIME_FRAMES_ARRAY,
  TOTAL,
  YearObject,
} from './../../Requirement';
import {
  getAggregatedDatapoints,
  getMetricAnswers,
  hasChildOnBULevel,
  isFullMetricOnBULevel,
} from './../AggregatedMetrics/AggregatedMetrics.hooks';
import {
  GetMetricWithChildrenQuery_,
  useGetAnswersForMetricsOnCompanyLevelQuery,
  useGetAnswersForMetricsOnGroupLevelQuery,
  useGetMetricWithChildrenQuery,
} from 'models';
import { useMemo } from 'react';
import { getFullMetricWithChildren } from '../AggregatedMetrics';
import { getNestedRows, getTagsCombination, MetricsTableData } from '../MetricAnswers.hooks';
import { useCompanyType } from 'utils/hooks';
import { useParams, useSearchParams } from 'react-router-dom';
import { AssessableMetrics } from '../Metrics';

export type ChartTableType = {
  name: string;
  unit: string;
  value: number;
};

export type FiltersType = {
  ruFilter?: string[];
  subFilter?: string;
  tagBreakdown?: string;
};

type BarType = { [a: string]: number | string };

const getFilteredTagRow = (nestedMetric: MetricsTableData, tag: string | undefined) => {
  const tagSubrows = nestedMetric.subRows?.find((row) => row.tagName === tag)?.subRows;
  const tagSubrowsChildren = tagSubrows?.map((row) => row?.tags ?? []);
  return { tagSubrows, tagSubrowsChildren };
};

export const useMetricBreakdown = (filters?: FiltersType) => {
  const {
    esrsAssessmentId = '',
    standardRef = '',
    metricRef = '',
    materialStandardId = '',
    reportingUnitId,
  } = useParams();
  const [searchParams] = useSearchParams();

  const { companyType } = useCompanyType();
  const isGroup = useMemo(() => companyType === 'group-company', [companyType]);
  const isReportingUnitLevel = useMemo(() => !!reportingUnitId, [reportingUnitId]);

  const singleTag = searchParams.get('tag') ?? undefined;
  const tagGroup = searchParams.get('tagGroup') ?? undefined;
  const tagChild = searchParams.get('tagChild') ?? undefined;

  // Metric query
  const { data: metricData, loading } = useGetMetricWithChildrenQuery({
    variables: {
      metricRef,
      materialStandardId,
    },
  });

  const metric = useMemo(
    () => metricData?.EsrsMetric[0] ?? ({} as GetMetricWithChildrenQuery_['EsrsMetric'][number]),
    [metricData]
  );

  const materialMetric = useMemo(
    () => metric.materialMetrics?.find((mm) => mm.materialStandardId === materialStandardId),
    [metric]
  );

  const nestedMetric = useMemo(
    () =>
      getNestedRows({ metric: metric as AssessableMetrics[number], materialStandardId, isGroup }),
    [metric]
  );

  const requiredMetricsRefs = useMemo(
    () => getFullMetricWithChildren(metric).map((m) => m.reference),
    [metric]
  );

  // Answers query
  const { data: ruData, loading: ruLoading } = useGetAnswersForMetricsOnCompanyLevelQuery({
    variables: { esrsAssessmentId },
    skip: !esrsAssessmentId,
  });
  const reportingUnitsAnswers = useMemo(() => ruData?.EsrsAssessment_by_pk, [ruData]);

  const { data: groupRuData, loading: loadingGroup } = useGetAnswersForMetricsOnGroupLevelQuery({
    variables: { esrsAssessmentId },
    skip: !isGroup || !esrsAssessmentId,
  });
  const groupReportingUnitsAnswers = useMemo(
    () => groupRuData?.EsrsAssessment_by_pk,
    [groupRuData]
  );

  // Tags options and values
  const tags = useMemo(() => {
    if (!materialMetric) return [];
    const tagOptions = materialMetric?.materialMetricTags ?? [];
    return tagOptions?.length ? getTagsCombination(tagOptions).allTagsArray : [];
  }, [materialMetric]);

  const tagOptions = useMemo(
    () => materialMetric?.materialMetricTags?.flatMap((mt) => mt?.tagType) ?? [],
    [materialMetric]
  );

  const tagValues = useMemo(
    () =>
      materialMetric?.materialMetricTags
        ?.find((tag) => tag.tagType === filters?.tagBreakdown)
        ?.valueOptions?.map((tag) => tag.tagValue) ?? [],
    [materialMetric, filters]
  );

  // BusinessUnit and Subsidiary options
  const businessUnitOptions = useMemo(() => {
    const requiredRUs =
      reportingUnitsAnswers?.reportingUnits.filter((ru) =>
        isFullMetricOnBULevel(metric)
          ? !ru.isCompanyLevel
          : hasChildOnBULevel(metric)
            ? ru
            : ru.isCompanyLevel
      ) ?? [];

    return requiredRUs.map((ru) => ({
      id: ru.id,
      name: ru.name,
    }));
  }, [reportingUnitsAnswers, materialMetric]);

  const subsidiaryOptions = useMemo(
    () =>
      groupReportingUnitsAnswers?.subsidiaryAssessments.map((assessment) => ({
        id: assessment.id,
        name: assessment.company.name,
      })),
    [groupReportingUnitsAnswers]
  );

  const filteredSubsidiaries = useMemo(
    () =>
      groupReportingUnitsAnswers?.subsidiaryAssessments.filter((su) =>
        filters?.subFilter?.length ? filters?.subFilter?.includes(su.id) : true
      ) ?? [],
    [groupReportingUnitsAnswers, filters]
  );

  const filteredBusinessUnits = useMemo(() => {
    if (isReportingUnitLevel)
      return reportingUnitsAnswers?.reportingUnits.filter((ru) => ru.id === reportingUnitId) ?? [];
    return (
      reportingUnitsAnswers?.reportingUnits.filter((ru) =>
        filters?.ruFilter?.length ? filters?.ruFilter?.includes(ru.id) : true
      ) ?? []
    );
  }, [reportingUnitsAnswers, filters, isReportingUnitLevel]);

  // Time frame
  const isYearly = useMemo(
    () => materialMetric?.frequency === FrequencyEnums.yearly,
    [materialMetric]
  );

  const requiredTimeFrames = useMemo(
    () => (isYearly ? [TimePeriodsEnums.year] : TIME_FRAMES_ARRAY),
    [materialMetric, isYearly]
  );

  // Get answers
  const answers = useMemo(
    () =>
      getMetricAnswers({
        metric,
        companyAnswersData: filteredBusinessUnits,
        groupAnswersData: filteredSubsidiaries,
        isGroup,
        standardRef,
        materialStandardId,
      }),
    [metric, filteredSubsidiaries, filteredBusinessUnits, isGroup, standardRef, materialStandardId]
  );

  // Results
  const { tagSubrows, tagSubrowsChildren } = useMemo(
    () => getFilteredTagRow(nestedMetric, tagGroup ?? tagChild),
    [nestedMetric, tagGroup, tagChild]
  );

  const metricResult = useMemo(() => {
    if (singleTag) {
      const subRows = nestedMetric.subRows?.flatMap((subrow) => [
        subrow,
        ...(subrow.subRows ?? []),
      ]);
      const subRowsTags = [subRows?.find((row) => row.tagName === singleTag)?.tags ?? []];

      return getAggregatedDatapoints({
        metric,
        answers,
        tags: subRowsTags,
      });
    }

    const requiredTags = !!tagGroup || !!tagChild ? tagSubrowsChildren : tags;
    return getAggregatedDatapoints({
      metric,
      answers,
      tags: requiredTags,
      filterAnswers: false,
      childrenMetrics: metric.childrenMetrics?.length ? requiredMetricsRefs : [],
    });
  }, [filters, answers, tags, tagGroup, singleTag, tagChild]);

  const tagGroupResults = useMemo(
    () =>
      tagSubrows?.map((row) => ({
        name: !!tagChild ? (row.metric.shortTitle ?? row.metric.title) : (row.tagName ?? ''),
        value: getAggregatedDatapoints({
          metric: row?.metric,
          answers,
          tags: [row?.tags ?? []],
          isChild: true,
          filterAnswers: !!tagChild,
        }).Year,
        unit: metric.unitOfMeasurement ?? 'N/A',
      })) ?? [],
    [metric, answers, tagSubrows, tagChild, tagGroup]
  );

  const tagValuesResults = useMemo(() => {
    const result: { [tag: string]: YearObject } = {};
    tagValues.forEach((tagValue) => {
      const filteredTags = tags?.filter((tag) => tag.some((value) => value.tagValue === tagValue));
      result[tagValue] = getAggregatedDatapoints({
        metric,
        answers,
        tags: filteredTags,
        filterAnswers: false,
      });
    });
    return result;
  }, [metric, answers, tags, tagValues]);

  const title = useMemo(() => {
    const metricTitle = metric?.shortTitle ?? metric.title;
    const rowTitle = singleTag ?? tagChild ?? tagGroup ?? metricTitle;
    const parentRowTitle = (singleTag || tagChild || tagGroup) && metricTitle;

    return `${rowTitle}${parentRowTitle ? ` (${parentRowTitle})` : ''}`;
  }, [metric, singleTag, tagChild, tagGroup]);

  // Table data
  const result = useMemo(() => {
    if (filters?.tagBreakdown) {
      const tagFilterRes = tagValues.map((tagValue) => ({
        name: tagValue,
        value: tagValuesResults[tagValue].Year,
        unit: metric.unitOfMeasurement ?? 'N/A',
      }));
      return tagFilterRes;
    }
    const res = [
      {
        name: title,
        value: metricResult.Year,
        unit: metric.unitOfMeasurement ?? 'N/A',
      },
      ...(!!tagGroup || !!tagChild ? tagGroupResults : []),
    ];
    return res;
  }, [
    metricResult,
    filters,
    tagValues,
    tagValuesResults,
    metric,
    tagGroup,
    tagGroupResults,
    tagChild,
  ]);

  // Chart data
  const chartData = useMemo(() => {
    if (filters?.tagBreakdown) {
      return requiredTimeFrames.map((timeframe) => {
        const bar: BarType = {
          name: timeframe === TimePeriodsEnums.year ? TOTAL : timeframe,
          unit: metric.unitOfMeasurement ?? 'N/A',
        };
        tagValues.forEach((tagValue) => {
          bar[tagValue] = tagValuesResults[tagValue][timeframe];
        });
        return bar;
      });
    }
    return requiredTimeFrames.map((timeframe) => ({
      name: timeframe === TimePeriodsEnums.year ? TOTAL : timeframe,
      value: metricResult[timeframe],
      unit: metric.unitOfMeasurement ?? 'N/A',
    }));
  }, [metricResult, tagValues, metric, requiredTimeFrames, filters, tagValuesResults]);

  return {
    result,
    chartData,
    metric,
    tagValues,
    tagOptions,
    businessUnitOptions,
    subsidiaryOptions,
    isGroup,
    singleTag,
    tagGroup,
    tagChild,
    isYearly,
    title,
    loading: ruLoading || loadingGroup || loading,
  };
};
