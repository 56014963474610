import { ContentHeader, ContentLayout, Loader } from 'Molecules';
import { useGetDRGroupData } from '../DisclosureRequirements.hooks';
import { DisclosureRequirementHeader } from './StandardPageComponents/DisclosureRequirementHeader';
import { useNavigate, useParams } from 'react-router-dom';
import {
  useBusinessUnitMetricRequiredTagsPerStandardQuery,
  useCompanyMetricRequiredTagsPerStandardQuery,
  useEsrsAssessmentQuery,
  useGetBuDisclosureRequirementMetricsQuery,
  useGroupMetricRequiredTagsPerStandardQuery,
} from 'models';
import { HStack, VStack } from '@chakra-ui/react';
import { DisclosureRequirementTable } from './StandardPageComponents/DisclosureRequirementsTable';
import { useMaterialStandardId } from 'containers/Esrs/EsrsAssessment.hooks';
import React, { useMemo } from 'react';
import { Button, Infobox } from 'Atoms';
import { SettingsIcon } from 'Tokens/Icons/Function';
import { useCurrentCompanyId } from 'utils/hooks';
import { DREnums } from '../..';
import { Typography } from 'Tokens';
import { ArrowUpRightIcon } from 'Tokens/Icons/Direction';
import { DataCollectionLevel } from '../../DataCollection';

export const StandardPage = () => {
  const navigate = useNavigate();
  const { companyId } = useCurrentCompanyId();
  const { esrsAssessmentId = '', reportingUnitId } = useParams();

  const { standard, sortedRequirements, loading } = useGetDRGroupData();
  const { companyAssessmentId: companyStandardId, parentAssessmentId: parentStandardId } =
    useMaterialStandardId(standard?.reference ?? '', esrsAssessmentId ?? '');

  const isBusinessUnit = useMemo(() => !!reportingUnitId, [reportingUnitId]);

  const { data: esrsAssessmentData } = useEsrsAssessmentQuery({
    variables: { esrsAssessmentId },
    skip: !esrsAssessmentId,
  });

  const isGroup = useMemo(
    () => esrsAssessmentData?.esrsAssessment?.company?.isGroupOwner ?? false,
    [esrsAssessmentData]
  );

  const { data: buData } = useGetBuDisclosureRequirementMetricsQuery({
    variables: {
      standardRef: standard?.reference ?? '',
      companyStandardId,
      parentStandardId: parentStandardId || companyStandardId,
    },
    skip: !isBusinessUnit,
  });

  const { data: groupStandardTagsData } = useGroupMetricRequiredTagsPerStandardQuery({
    variables: {
      materialStandardId: companyStandardId,
    },
    skip: !isGroup || !companyStandardId || isBusinessUnit,
  });

  const { data: companyStandardTagsData } = useCompanyMetricRequiredTagsPerStandardQuery({
    variables: {
      materialStandardId: companyStandardId,
    },
    skip: isGroup || !companyStandardId || isBusinessUnit,
  });

  const { data: businessUnitStandardTagsData } = useBusinessUnitMetricRequiredTagsPerStandardQuery({
    variables: {
      materialStandardId: companyStandardId,
      parentStandardId: parentStandardId || companyStandardId,
    },
    skip: isGroup || !companyStandardId || !isBusinessUnit,
  });

  const standardTagsData = useMemo(() => {
    if (isGroup) {
      return groupStandardTagsData;
    }
    if (isBusinessUnit) {
      return businessUnitStandardTagsData;
    }
    return companyStandardTagsData;
  }, [
    isGroup,
    isBusinessUnit,
    groupStandardTagsData,
    companyStandardTagsData,
    businessUnitStandardTagsData,
  ]);
  const drRefsMissingTagsConfiguration: Set<string> = new Set();
  const dataCollectionLevel = useMemo(
    () => (isGroup ? DataCollectionLevel.subsidiaries : DataCollectionLevel.reportingUnits),
    [isGroup]
  );
  const someTagsNotConfigured = useMemo(() => {
    const materialMetrics = standardTagsData?.esrs_MaterialMetric;
    let hasMissingTags = false;
    materialMetrics?.forEach((mm) => {
      if (
        mm.childrenMetrics_aggregate &&
        mm.childrenMetrics_aggregate?.aggregate?.count === 0 &&
        mm.dataCollection &&
        mm.dataCollection === dataCollectionLevel
      ) {
        return false;
      }

      mm.metric.requiredTags.some((requiredTag) => {
        const matchingTag = mm.materialMetricTags?.find((tag) => tag.tagType === requiredTag.type);
        if (matchingTag === undefined) {
          drRefsMissingTagsConfiguration.add(mm.metric.disclosureRequirementRef);
          hasMissingTags = true;
        }
        return !matchingTag;
      });
    });
    return hasMissingTags;
  }, [standardTagsData]);

  const buDisclosureRequirements = useMemo(
    () =>
      [...(buData?.requirements ?? [])].sort((a, b) => {
        if (a.order !== b.order) {
          return a.order - b.order;
        }
        return a.reference.localeCompare(b.reference);
      }),
    [buData]
  );

  const disclosureRequirementsRefs = isBusinessUnit
    ? buDisclosureRequirements
        .filter((dr) => dr.type !== DREnums.action && dr.type !== DREnums.target)
        .map((dr) => dr.reference)
    : sortedRequirements.flatMap((sr) =>
        sr
          .slice(1)
          .filter((dr: any) => dr?.type !== DREnums.action && dr?.type !== DREnums.target)
          .map((dr) => dr.key.split('_')[1])
      );

  if (loading) return <Loader />;

  return (
    <ContentLayout
      backButton
      header={
        <HStack w="100%" justifyContent="space-between" alignItems="start">
          <ContentHeader title={standard?.title} subtitle={standard?.reference} />
          <Button
            variant="ghost"
            size="sm"
            leftIcon={<SettingsIcon />}
            onClick={() => {
              navigate(
                `/${companyId}/esrs/${esrsAssessmentId}/settings/data-collection/${standard?.reference}`
              );
            }}
          >
            Settings
          </Button>
        </HStack>
      }
      variant="narrow"
    >
      <VStack alignItems="stretch" gap="28px" mt="24px">
        <DisclosureRequirementHeader
          isGroup={esrsAssessmentData?.esrsAssessment?.company.isGroupOwner ?? false}
          owner={esrsAssessmentData?.esrsAssessment?.projectLeader}
          disclosureRequirementsRefs={disclosureRequirementsRefs}
          parentAssessmentId={esrsAssessmentData?.esrsAssessment?.parentAssessment?.id}
          requirements={isBusinessUnit ? buDisclosureRequirements : sortedRequirements}
        />
        {someTagsNotConfigured && (
          <Infobox
            status="warning"
            closable={false}
            title="Some data points are misconfigured"
            description="Some data points in this standard were not set up correctly. Please go to the standard setup to see details and to complete the required configurations."
            extra={
              <HStack
                onClick={() => {
                  navigate(
                    `/${companyId}/esrs/${esrsAssessmentId}/settings/data-collection/${standard?.reference}`
                  );
                }}
                cursor="pointer"
              >
                <Typography fontSize="14px" fontWeight={'500'} spacing={0} variant="body">
                  Complete setup
                </Typography>
                <ArrowUpRightIcon />
              </HStack>
            }
          />
        )}

        {isBusinessUnit ? (
          <DisclosureRequirementTable
            title={'Metrics'}
            requirements={buDisclosureRequirements}
            owner={esrsAssessmentData?.esrsAssessment?.projectLeader}
            isGroup={esrsAssessmentData?.esrsAssessment?.company.isGroupOwner ?? false}
            parentAssessmentId={esrsAssessmentData?.esrsAssessment?.parentAssessment?.id}
            drRefsMissingTagsConfiguration={drRefsMissingTagsConfiguration}
          />
        ) : (
          sortedRequirements.map((requirements) => (
            <DisclosureRequirementTable
              title={requirements[0].title}
              requirements={requirements.slice(1)}
              owner={esrsAssessmentData?.esrsAssessment?.projectLeader}
              isGroup={esrsAssessmentData?.esrsAssessment?.company.isGroupOwner ?? false}
              parentAssessmentId={esrsAssessmentData?.esrsAssessment?.parentAssessment?.id}
              drRefsMissingTagsConfiguration={drRefsMissingTagsConfiguration}
            />
          ))
        )}
      </VStack>
    </ContentLayout>
  );
};
