import { VStack, useDisclosure, HStack, Skeleton, Box } from '@chakra-ui/react';
import { Typography } from 'Tokens';
import React, { Suspense, useEffect, useMemo, useState } from 'react';
import {
  useGetAggregatedMetrics,
  AggregatedQualitativeAnswers,
  processNarrativeMetrics,
  hasQuarterlyFrequency,
  isFullMetricQuarterlyLevel,
  hasChildOnBULevel,
  hasChildOnSubLevel,
  AggregatedMetricsTableData,
  MetricType,
} from './AggregatedMetrics.hooks';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Button, IconButton, Infobox, Tooltip } from 'Atoms';
import { HelpIcon, WarningIcon } from 'Tokens/Icons/Status';
import { LearnMoreDrawer, Loader, MetricLearnMoreHeader } from 'Molecules';
import {
  GetAnswersForMetricsOnCompanyLevelDocument_,
  GetAnswersForMetricsOnGroupLevelDocument_,
  QuestionType_Enum_,
} from 'models';
import { FrequencyEnums, TimePeriods, TimePeriodsEnums } from '../../Requirement';
import { useCurrentCompany, useToast } from 'utils/hooks';
import { DownloadIcon, UploadIcon } from 'Tokens/Icons/Function';
import {
  SelectedMetric,
  useGetAggregatedMetricsData,
  DREnums,
  MetricsTableData,
} from 'containers/Esrs';
import { OptOutModal } from '../OptOutModal';
import { useApolloClient } from '@apollo/client';
import { LinkAnchor } from 'Molecules/LinkAnchor';
import { MetricSidebar } from '../MetricSidebar';
import { IconArrowNarrowDown } from '@tabler/icons-react';
import { MetricsAISection } from '../MetricAI';
import { Menu } from 'Molecules/Menu';
import { MetricsExcelUploadModal, useExportEsrsMetricTable } from '../MetricsExcel';
import { GroupOrderOptions, GroupOrderSelector, QuarterSelector } from './AggregatedMetricsUtils';
import { isEqual, orderBy, uniqBy } from 'lodash';
import { getMetricRefNumber } from '../Metrics.hooks';
import { MetricsTable } from '../MetricsTable/MetricsTable';
import { AnswersApprovalWarningModal } from '../MetricAI/AIUtils';
import { MDRM } from '../MDRM/MDRM';
import { DataCollectionLevel } from '../../../DataCollection';
import { ArrowUpRightIcon } from 'Tokens/Icons/Direction';

export type GeneratedSummaryStatus =
  | {
      metricRef: string;
      isApproved: boolean;
    }
  | undefined;

const validateChildrenMetricsDataCollectionLevel = (
  dataCollectionLevel: string | null,
  isGroup: boolean,
  isBusinessUnit: boolean
) => {
  return isGroup
    ? dataCollectionLevel === DataCollectionLevel.group
    : !isBusinessUnit
    ? dataCollectionLevel === DataCollectionLevel.company
    : dataCollectionLevel === DataCollectionLevel.reportingUnits;
};

const metricsMissingRequiredTags = (
  metrics: MetricType[],
  companyAssessmentId: string,
  isGroup: boolean,
  isBusinessUnit: boolean
) => {
  let hasMissingTags: boolean = false;
  const metricsMissingTagsList: string[] = [];

  metrics.forEach((metric) => {
    const dataCollectionLevel = metric.materialMetrics[0]?.dataCollection;
    let validateChildrenMetrics;
    const hasChildrenMetrics = metric.childrenMetrics.length > 0;

    const materialMetric = metric.materialMetrics?.find(
      (mm) => mm.materialStandardId === companyAssessmentId
    );
    const requiredTags = metric.adminPanelTags.filter((tag) => !tag.isOptional);

    const isMissingTag = requiredTags.some((requiredTag) => {
      const matchingTag = materialMetric?.materialMetricTags?.find(
        (tag) => tag.tagType === requiredTag.type
      );
      return !matchingTag || matchingTag.materialTagValues.length === 0;
    });

    if (hasChildrenMetrics) {
      validateChildrenMetrics = metric.childrenMetrics?.some((childMetric) => {
        const childDataCollection = childMetric.childMetric?.materialMetrics?.[0]?.dataCollection;
        if (!childDataCollection) return false;

        return validateChildrenMetricsDataCollectionLevel(
          childDataCollection,
          isGroup,
          isBusinessUnit
        );
      });
    } else {
      validateChildrenMetrics = validateChildrenMetricsDataCollectionLevel(
        dataCollectionLevel ?? '',
        isGroup,
        isBusinessUnit
      );
    }

    if (isMissingTag && validateChildrenMetrics) {
      metricsMissingTagsList.push(metric.reference);
      hasMissingTags = true;
    }
  });
  return [metricsMissingTagsList, hasMissingTags];
};

const flattenMetricsNotAggregatable = (metrics) => {
  const result = [];

  for (const row of metrics) {
    result.push({ ...row });
    if (row.subRows && row.subRows.length > 0) {
      result.push(...flattenMetricsNotAggregatable(row.subRows));
    }
  }
  return result;
};

const flattenMetricsWithTags = (metricsWithTags: AggregatedMetricsTableData[]) => {
  const metricsData: MetricType[] = [];

  const flattenChildrenMetric = (metrics: AggregatedMetricsTableData[]) => {
    metrics.forEach((metric) => {
      if (metric.childMetric) {
        metricsData.push(metric.childMetric);
        if (metric.childMetric?.childrenMetrics?.length > 0) {
          flattenChildrenMetric(metric.childMetric?.childrenMetrics);
        }
      } else {
        metricsData.push(metric.metric);
        if (metric.metric?.childrenMetrics?.length > 0) {
          flattenChildrenMetric(metric.metric?.childrenMetrics);
        }
      }
    });
  };
  flattenChildrenMetric(metricsWithTags);
  return metricsData;
};

export const MetricsWarningInputPage = ({
  warningIconColor,
  padding,
  toolTipLabel,
  contentColor,
  content,
}: {
  warningIconColor: string;
  padding: string;
  toolTipLabel: string;
  contentColor: string;
  content: string;
}) => {
  return (
    <HStack justifyContent="left" w="100%" spacing={0} pl={padding} pb={'8px'}>
      <WarningIcon boxSize="12px" color={warningIconColor} />
      <Tooltip label={toolTipLabel} placement="bottom-start">
        <Typography fontSize="12px" fontWeight={'400'} ml={1} color={contentColor} noOfLines={1}>
          {content}
        </Typography>
      </Tooltip>
    </HStack>
  );
};

export const AggregatedMetrics = ({
  extraHeaderContent,
  isGeneratingAnswers = false,
  withAssociation = false,
  rowData,
  setRowData,
  setMetricSidebarOpen,
}: {
  extraHeaderContent?: React.ReactNode;
  isGeneratingAnswers?: boolean;
  withAssociation?: boolean;
  withBorder?: boolean;
  rowData?: MetricsTableData & { sourceData?: AggregatedQualitativeAnswers[number] };
  setRowData: (
    param: (MetricsTableData & { sourceData?: AggregatedQualitativeAnswers[number] }) | undefined
  ) => void;
  setMetricSidebarOpen: (val: boolean) => void;
}) => {
  const client = useApolloClient();
  const navigate = useNavigate();
  const {
    esrsAssessmentId = '',
    standardRef = '',
    disclosureRequirementRef,
    drType,
    reportingUnitId,
  } = useParams();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isDrawerOpen, onOpen: onDrawerOpen, onClose: onDrawerClose } = useDisclosure();
  const { isOpen: isImportOpen, onOpen: onImportOpen, onClose: onImportClose } = useDisclosure();
  const [selectedMetric, setSelectedMetric] = useState<SelectedMetric>();
  const [selectedQuarter, setSelectedQuarter] = useState<TimePeriods>(TimePeriodsEnums.year);
  const [selectedRow, setSelectedRow] = useState('');
  const [groupOrOrder, setGroupOrOrder] = useState(GroupOrderOptions.byType);
  const [selectedExcelMetric, setSelectedExcelMetric] = useState<MetricsTableData>();
  const exportMetrics = useExportEsrsMetricTable();
  const toast = useToast();

  const [generatedSummaryStatus, setGeneratedSummaryStatus] = useState<GeneratedSummaryStatus>();

  const { company } = useCurrentCompany();
  const [searchParams] = useSearchParams();
  const urlMetricRef = useMemo(() => searchParams.get('metricRef'), [searchParams]);
  const isBusinessUnit = useMemo(() => !!reportingUnitId, [reportingUnitId]);

  const {
    metricDR,
    metrics,
    companyAssessmentId,
    parentAssessmentId,
    companyLevelReportingUnitId,
    projectLeader,
    isGroup,
    requiredMaterialMetrics,
    companyAnswersData,
    groupAnswersData,
    dataLoading,
  } = useGetAggregatedMetricsData(withAssociation);

  const {
    aggregatedMetrics,
    qualitativeMetrics: aggregatedQualitativeMetrics,
    aggregatedMetricsAnswers,
  } = useGetAggregatedMetrics(
    metrics ?? [],
    standardRef,
    companyAssessmentId,
    companyAnswersData,
    groupAnswersData,
    isGroup
  );

  const {
    isOpen: isApprovalWarningModalOpen,
    onOpen: onApprovalWarningModalOpen,
    onClose: onApprovalWarningModalClose,
  } = useDisclosure();

  const { title = '', reference = '', description = '' } = metricDR || {};
  const isMetricDisclosure = useMemo(() => drType === DREnums.metric, [drType]);
  const [numberOfRows, setNumberOfRows] = useState<Record<string, number>>({});
  const [isComments, setIsComments] = useState<boolean>(false);
  const isActionOrPolicyOrTarget = useMemo(
    () => drType === DREnums.action || drType === DREnums.policy || drType === DREnums.target,
    [drType]
  );
  const isTarget = useMemo(() => drType === DREnums.target, [drType]);

  useEffect(() => {
    setRowData(undefined);
  }, [disclosureRequirementRef]);

  useEffect(() => {
    if (rowData) setMetricSidebarOpen(true);
    else setMetricSidebarOpen(false);
  }, [rowData]);

  const summaryQualitativeMetrics = useMemo(
    () =>
      isGroup
        ? aggregatedQualitativeMetrics.filter((metric) => hasChildOnSubLevel(metric)) ?? []
        : aggregatedQualitativeMetrics.filter((metric) => hasChildOnBULevel(metric)) ?? [],
    [aggregatedQualitativeMetrics, isGroup]
  );

  useEffect(() => {
    client.refetchQueries({
      include: [
        GetAnswersForMetricsOnCompanyLevelDocument_,
        ...(isGroup ? [GetAnswersForMetricsOnGroupLevelDocument_] : []),
      ],
    });
  }, [isGroup]);

  // Quarter selection
  const [areMetricsYearly, areMetricsQuarterly] = useMemo(
    () => [
      aggregatedMetrics.every(({ metric }) => !hasQuarterlyFrequency(metric)),
      aggregatedMetrics.every(({ metric }) => isFullMetricQuarterlyLevel(metric)),
    ],
    [aggregatedMetrics]
  );

  useEffect(() => {
    if (areMetricsYearly) setSelectedQuarter(TimePeriodsEnums.year);
    else if (areMetricsQuarterly) setSelectedQuarter(TimePeriodsEnums.q1);
  }, [areMetricsYearly]);

  const [isSelectedMetricYearly, isSelectedMetricQuarterly] = useMemo(() => {
    return [
      selectedExcelMetric ? !hasQuarterlyFrequency(selectedExcelMetric?.metric) : false,
      selectedExcelMetric ? isFullMetricQuarterlyLevel(selectedExcelMetric?.metric) : false,
    ];
  }, [selectedExcelMetric]);

  const allFilteredMetrics = useMemo(() => {
    const metricArray: string[] = [];
    const filterMetrics = (metric: MetricsTableData, hidden?: boolean): MetricsTableData => {
      if (metric.subRows) {
        if (metricArray.includes(metric.metric.reference) && metric.parentMetric) {
          return {
            ...metric,
            hidden: hidden,
            subRows: [
              {
                metric: metric.metric,
                referenceToSource: metric.metric?.reference,
                hidden: hidden,
                subRows: metric.subRows.map((subrow) => ({
                  ...subrow,
                  hidden: true,
                  subRows: subrow?.subRows?.map((row) => filterMetrics(row, true)),
                })),
              },
            ],
          };
        }
        metricArray.push(metric.metric.reference);
        const subRows = metric?.subRows?.map((row) => filterMetrics(row, hidden));
        if (subRows) {
          return { ...metric, subRows: subRows, hidden: hidden };
        }
      }
      return { ...metric, hidden: hidden };
    };
    return [...aggregatedMetrics.map((metric) => filterMetrics(metric))];
  }, [aggregatedMetrics]);

  const narrativeMetrics = useMemo(() => {
    const allNarrativeMetrics = aggregatedMetrics.filter(
      (met) => met.metric.metricType !== QuestionType_Enum_.Decimal_
    );

    return allNarrativeMetrics.map((m) => processNarrativeMetrics(m, aggregatedMetricsAnswers));
  }, [aggregatedMetrics, aggregatedMetricsAnswers]);

  const orderedMetrics = useMemo(() => {
    const quantitativeMetrics = allFilteredMetrics.filter(
      (m) => m.metric.metricType === QuestionType_Enum_.Decimal_
    );
    if (groupOrOrder !== GroupOrderOptions.byEsrsOrder) {
      return quantitativeMetrics.filter((m) =>
        selectedQuarter === TimePeriodsEnums.year ? true : hasQuarterlyFrequency(m.metric)
      );
    } else {
      const mixedMetrics = [...quantitativeMetrics, ...narrativeMetrics];
      return uniqBy(
        orderBy(
          mixedMetrics,
          [
            (metric) => metric.metric.globalOrder === null,
            (metric) => metric.metric.globalOrder,
            (metric) => metric.metric.order,
          ],
          ['asc', 'asc', 'asc']
        ).filter((met) =>
          selectedQuarter === TimePeriodsEnums.year
            ? true
            : met.metric.materialMetrics?.[0]?.frequency === FrequencyEnums.quarterly
        ),
        'metric.reference'
      );
    }
  }, [
    groupOrOrder,
    allFilteredMetrics,
    narrativeMetrics,
    aggregatedMetricsAnswers,
    selectedQuarter,
  ]);

  const flattenedNarrativeMetrics = useMemo(
    () => flattenMetricsWithTags(narrativeMetrics),
    [narrativeMetrics]
  );

  const [narrativeMetricsMissingTagsConfiguration, someNarrativeTagsNotConfigured] = useMemo(() => {
    return metricsMissingRequiredTags(
      flattenedNarrativeMetrics,
      companyAssessmentId,
      isGroup,
      isBusinessUnit
    );
  }, [flattenedNarrativeMetrics, companyAssessmentId, isGroup, isBusinessUnit]);

  const isOnlyNarrative = useMemo(
    () => orderedMetrics.every((met) => met.metric.metricType !== QuestionType_Enum_.Decimal_),
    [orderedMetrics]
  );

  const showNarrativeSection = useMemo(
    () =>
      groupOrOrder === GroupOrderOptions.byType &&
      selectedQuarter === TimePeriodsEnums.year &&
      (!!narrativeMetrics.length || !!summaryQualitativeMetrics.length),
    [groupOrOrder, selectedQuarter, narrativeMetrics, summaryQualitativeMetrics]
  );

  //Scroll
  useEffect(() => {
    setTimeout(() => {
      const element = document.getElementById(urlMetricRef ?? '');
      if (element && urlMetricRef === element.id) {
        setTimeout(() => {
          element.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'center',
          });
        }, 300);
      }
    }, 500);
  }, [aggregatedMetrics, urlMetricRef, allFilteredMetrics]);

  const toggleShowMore = (category: string) => {
    setNumberOfRows((prevStates) => ({
      ...prevStates,
      [category]: (prevStates[category] || 5) + 10,
    }));
  };

  const getTotalRows = (data: MetricsTableData[]): number => {
    let totalRows = data.filter((row) => !row.hidden).length;
    for (const row of data) {
      if (row.subRows && row.subRows.length > 0) {
        totalRows += getTotalRows(row.subRows);
      }
    }
    return totalRows;
  };

  // Reset summary status when rowData changes
  useEffect(() => {
    if (!!generatedSummaryStatus && rowData?.metric.reference !== generatedSummaryStatus.metricRef)
      setGeneratedSummaryStatus(undefined);
  }, [rowData, generatedSummaryStatus]);

  const flattenAggregatedMetrics = useMemo(
    () => flattenMetricsNotAggregatable(aggregatedMetrics),
    [aggregatedMetrics]
  );

  const filteredNotAggregatableMetrics = useMemo(
    () =>
      flattenAggregatedMetrics?.filter((mm) => {
        return (
          mm.metric?.notAggregatable === true &&
          (mm.metric?.materialMetrics[0]?.dataCollection === DataCollectionLevel.subsidiaries ||
            mm.metric?.materialMetrics[0]?.dataCollection === DataCollectionLevel.reportingUnits) &&
          !isBusinessUnit
        );
      }),
    [flattenAggregatedMetrics, isBusinessUnit]
  );

  const notAggregatableMetrics = useMemo(
    () => filteredNotAggregatableMetrics?.map((metric) => metric.metric?.reference),
    [filteredNotAggregatableMetrics]
  );

  const parentNotAggregatableMetrics: Set<string> = new Set(
    useMemo(
      () =>
        filteredNotAggregatableMetrics
          ?.map((metric) => metric.metric?.parentMetrics.map((pm) => pm.parentMetricRef))
          .flat(),
      [filteredNotAggregatableMetrics]
    )
  );

  const flattenOrderedMetrics = useMemo(
    () => flattenMetricsWithTags(orderedMetrics),
    [orderedMetrics]
  );

  const [metricsMissingTagsConfiguration, someQuantitativeTagsNotConfigured] = useMemo(() => {
    return metricsMissingRequiredTags(
      flattenOrderedMetrics,
      companyAssessmentId,
      isGroup,
      isBusinessUnit
    );
  }, [flattenOrderedMetrics, companyAssessmentId, isGroup, isBusinessUnit]);

  if (dataLoading) return <Loader />;
  return (
    <HStack w="100%" alignItems="start" spacing="16px">
      <VStack spacing={isMetricDisclosure ? '8px' : '12px'} alignItems="start" w="100%">
        {isActionOrPolicyOrTarget ? (
          <></>
        ) : (
          <VStack spacing="8px" alignItems="start" w="100%">
            <HStack w="100%" justifyContent="space-between">
              <HStack spacing="6px" alignItems="center" justifyContent="space-between" w="100%">
                <Typography variant="h3" as="span">
                  Data points
                </Typography>
                <HStack w="40%" justifyContent="flex-end">
                  <GroupOrderSelector
                    groupOrOrder={groupOrOrder}
                    setGroupOrOrder={setGroupOrOrder}
                  />
                  {!areMetricsYearly && (
                    <QuarterSelector
                      defaultQuarter={selectedQuarter as TimePeriodsEnums}
                      setSelectedQuarter={setSelectedQuarter}
                    />
                  )}
                  <IconButton
                    aria-label="learn more"
                    variant="ghost"
                    icon={<HelpIcon />}
                    size="sm"
                    onClick={() => {
                      setSelectedMetric({
                        reference: reference,
                        title: title,
                        description: description ?? '',
                        isDisclosure: true,
                      });
                      onDrawerOpen();
                    }}
                  />
                </HStack>
              </HStack>
            </HStack>
          </VStack>
        )}

        <VStack
          spacing="40px"
          alignItems="stretch"
          w="100%"
          minH="fit-content"
          mb={isTarget ? '' : '40px'}
        >
          {(!areMetricsYearly || !(drType === DREnums.action || drType == DREnums.target)) && (
            <VStack alignItems="stretch" gap="12px" w="100%">
              {!areMetricsYearly && (
                <Infobox
                  status="neutral"
                  closable={false}
                  w="100%"
                  title={
                    selectedQuarter === TimePeriodsEnums.year
                      ? 'Full year view'
                      : `Quarter view: ${selectedQuarter}`
                  }
                  description={
                    selectedQuarter === TimePeriodsEnums.year
                      ? 'You are looking now at the full year view. Some metrics in this disclosure requirement are collected quarterly — to see data per quarter, switch between views above.'
                      : 'You are looking now at metrics collected quarterly. To see the full year overview and narrative data points, switch to the "Full year" view above.'
                  }
                />
              )}
              {!(drType === DREnums.action || drType == DREnums.target) && (
                <MetricsAISection
                  reportingUnitId={companyLevelReportingUnitId}
                  materialStandardId={companyAssessmentId}
                  disclosureRequirementRef={disclosureRequirementRef ?? ''}
                  allFilteredMetrics={allFilteredMetrics}
                  isGroup={isGroup}
                  rowData={rowData}
                  setRowData={setRowData}
                  onOpen={onOpen}
                  selectedQuarter={selectedQuarter}
                  selectedMetric={selectedMetric}
                  setSelectedMetric={setSelectedMetric}
                  esrsAssessmentProjectLeader={projectLeader}
                  aggregatedMetricsAnswers={aggregatedMetricsAnswers}
                  qualitativeMetrics={narrativeMetrics}
                  summaryQualitativeMetrics={summaryQualitativeMetrics}
                  isPolicy={drType === DREnums.policy}
                  parentStandardId={parentAssessmentId}
                  requiredMaterialMetrics={requiredMaterialMetrics}
                />
              )}
              {(someQuantitativeTagsNotConfigured || someNarrativeTagsNotConfigured) &&
                notAggregatableMetrics.length === 0 && (
                  <Infobox
                    status="warning"
                    closable={false}
                    withIcon={false}
                    w="100%"
                    minH="0px"
                    p="0px"
                    containerStyles={{ alignItems: 'center', pl: '8px' }}
                    title={
                      <HStack gap="0px">
                        <VStack justifyContent="flex-start" mr="8px">
                          <WarningIcon color="text.warning" />
                        </VStack>
                        <Typography fontSize="14px" fontWeight={'500'} variant="body" mr="4px">
                          Some data points are misconfigured.{' '}
                        </Typography>
                        <Typography fontSize="14px" fontWeight={'400'} variant="body">
                          Complete the required configurations in the DR setup.
                        </Typography>
                      </HStack>
                    }
                    rightElement={
                      <Button
                        variant="ghost"
                        size="md"
                        rightIcon={<ArrowUpRightIcon />}
                        onClick={() => {
                          navigate(
                            `/${company.id}/esrs/${esrsAssessmentId}/settings/data-collection/${standardRef}/disclosure-requirement/${disclosureRequirementRef}`
                          );
                        }}
                      >
                        Complete setup
                      </Button>
                    }
                  />
                )}
              {notAggregatableMetrics.length > 0 && (
                <Infobox
                  status="warning"
                  closable={false}
                  withIcon={false}
                  w="100%"
                  minH="0px"
                  p="0px"
                  containerStyles={{ alignItems: 'center', pl: '8px' }}
                  title={
                    <HStack gap="0px">
                      <VStack justifyContent="flex-start" mr="8px">
                        <WarningIcon color="text.warning" />
                      </VStack>
                      <Typography fontSize="14px" fontWeight={'500'} variant="body">
                        Some data points are{' '}
                        {someQuantitativeTagsNotConfigured || someNarrativeTagsNotConfigured
                          ? 'misconfigured or '
                          : ''}{' '}
                        advised to be reviewed.
                      </Typography>
                    </HStack>
                  }
                  rightElement={
                    <Button
                      variant="ghost"
                      size="md"
                      rightIcon={<ArrowUpRightIcon />}
                      onClick={() => {
                        navigate(
                          `/${company.id}/esrs/${esrsAssessmentId}/settings/data-collection/${standardRef}/disclosure-requirement/${disclosureRequirementRef}`
                        );
                      }}
                    >
                      {someQuantitativeTagsNotConfigured || someNarrativeTagsNotConfigured
                        ? 'Complete setup'
                        : 'Review setup'}
                    </Button>
                  }
                />
              )}
            </VStack>
          )}
          {!(groupOrOrder === GroupOrderOptions.byType && isOnlyNarrative) && (
            <VStack alignItems="start" gap="20px" w="100%">
              {groupOrOrder === GroupOrderOptions.byType && (
                <Typography variant="h3">Metrics</Typography>
              )}
              <VStack alignItems="start" gap="40px" w="100%">
                {orderedMetrics?.map((metric) => {
                  const isNarrative = metric.metric.metricType !== QuestionType_Enum_.Decimal_;
                  const rowsCount = getTotalRows([metric]);
                  const remainingCount =
                    rowsCount - (numberOfRows[metric.metric?.reference ?? ''] ?? 5) > 10
                      ? 10
                      : rowsCount - (numberOfRows[metric.metric?.reference ?? ''] ?? 5);
                  const showMoreButton = remainingCount > 0;
                  const isTableMetricYearly = metric?.metric.materialMetrics.every(
                    (m) => m?.frequency === FrequencyEnums.yearly
                  );
                  const isTableMetricQuarterly = metric?.metric.materialMetrics.every(
                    (m) => m?.frequency === FrequencyEnums.quarterly
                  );
                  return (
                    <Skeleton isLoaded={isMetricDisclosure ? true : !dataLoading} width="100%">
                      <VStack
                        alignItems="start"
                        id={metric.metric.reference}
                        spacing="6px"
                        w="100%"
                      >
                        <HStack justifyContent="space-between" w="100%">
                          <LinkAnchor
                            hideIcon
                            id={metric.metric.reference}
                            text={`${metric.metric.title}`}
                            reference={getMetricRefNumber(metric.metric)}
                          />

                          <HStack spacing="4px">
                            <Button
                              variant="ghost"
                              leftIcon={<UploadIcon />}
                              onClick={() => {
                                onImportOpen();
                                setSelectedExcelMetric(metric);
                              }}
                            >
                              Import
                            </Button>
                            <Menu
                              size="md"
                              sections={[
                                {
                                  actions: [
                                    {
                                      id: 'download',
                                      title: 'Download Excel file',
                                      variant: 'ghost',
                                      onClick: () => {
                                        toast({
                                          text: 'Downloading file...',
                                          closable: true,
                                          duration: null,
                                        });
                                        if (metric) {
                                          exportMetrics(
                                            [metric],
                                            disclosureRequirementRef ?? '',
                                            company?.name ?? 'Company',
                                            isTableMetricQuarterly,
                                            isTableMetricYearly,
                                            companyAssessmentId,
                                            companyLevelReportingUnitId
                                          );
                                        }
                                      },
                                      leftElement: <DownloadIcon />,
                                    },
                                  ],
                                },
                              ]}
                            />
                          </HStack>
                        </HStack>
                        <MetricsTable
                          key={metric.metric.reference}
                          metrics={[metric]}
                          selectedQuarter={selectedQuarter}
                          isGroup={isGroup}
                          currency={company?.currency ?? ''}
                          isNarrative={isNarrative}
                          materialStandardId={companyAssessmentId}
                          companyReportingUnit={companyLevelReportingUnitId}
                          esrsAssessmentProjectLeader={projectLeader}
                          rowData={rowData}
                          setIsComments={setIsComments}
                          answersData={aggregatedMetricsAnswers}
                          setRowData={setRowData}
                          pageSize={numberOfRows[metric.metric.reference]}
                          allMetrics={allFilteredMetrics}
                          setNumberOfRows={setNumberOfRows}
                          isMetricDr={isMetricDisclosure}
                          isGeneratingAnswers={isGeneratingAnswers}
                          onApprovalWarningModalOpen={onApprovalWarningModalOpen}
                          parentStandardId={parentAssessmentId}
                          requiredMaterialMetrics={requiredMaterialMetrics}
                          metricsMissingTagsConfiguration={metricsMissingTagsConfiguration}
                          notAggregatableMetrics={notAggregatableMetrics}
                          parentNotAggregatableMetrics={parentNotAggregatableMetrics}
                        />
                        {showMoreButton && !isNarrative && (
                          <Button
                            mt="2px"
                            variant="ghost"
                            size="sm"
                            onClick={() => toggleShowMore(metric.metric.reference)}
                          >
                            <HStack spacing="8px">
                              <IconArrowNarrowDown size="16px" />
                              <Typography variant="bodyStrong" size="sm">
                                Load {remainingCount} more
                              </Typography>
                            </HStack>
                          </Button>
                        )}
                        <Box w="100%" mt={showMoreButton ? '-34px' : '0px'}>
                          <MDRM
                            metric={metric}
                            companyReportingUnitId={companyLevelReportingUnitId}
                            selectedQuarter={selectedQuarter}
                            setRowData={setRowData}
                          />
                        </Box>
                      </VStack>
                    </Skeleton>
                  );
                })}
              </VStack>
            </VStack>
          )}
          {showNarrativeSection && (
            <VStack alignItems="stretch" gap="20px" w="100%">
              <Typography variant="h3">Narrative data points</Typography>
              <Skeleton isLoaded={isMetricDisclosure ? true : !dataLoading} w="100%">
                <VStack alignItems="start" spacing="6px" w="100%">
                  <MetricsTable
                    metrics={narrativeMetrics}
                    isNarrative
                    selectedQuarter={selectedQuarter}
                    isGroup={isGroup}
                    currency={company?.currency ?? ''}
                    setSelectedRow={setSelectedRow}
                    materialStandardId={companyAssessmentId}
                    companyReportingUnit={companyLevelReportingUnitId}
                    esrsAssessmentProjectLeader={projectLeader}
                    rowData={rowData}
                    isGeneratingAnswers={isGeneratingAnswers}
                    setIsComments={setIsComments}
                    onApprovalWarningModalOpen={onApprovalWarningModalOpen}
                    answersData={aggregatedMetricsAnswers}
                    setRowData={setRowData}
                    allMetrics={allFilteredMetrics}
                    setNumberOfRows={setNumberOfRows}
                    isMetricDr={isMetricDisclosure}
                    parentStandardId={parentAssessmentId}
                    requiredMaterialMetrics={requiredMaterialMetrics}
                    metricsMissingTagsConfiguration={narrativeMetricsMissingTagsConfiguration}
                    notAggregatableMetrics={notAggregatableMetrics}
                    parentNotAggregatableMetrics={parentNotAggregatableMetrics}
                  />
                </VStack>
              </Skeleton>
            </VStack>
          )}
        </VStack>
        <LearnMoreDrawer
          header={selectedMetric?.isDisclosure ? selectedMetric.title : undefined}
          isOpen={isDrawerOpen}
          onClose={onDrawerClose}
          description={selectedMetric?.description ?? ''}
          customHeader={
            !selectedMetric?.isDisclosure ? (
              <MetricLearnMoreHeader
                metricRef={selectedMetric?.reference ?? ''}
                tags={selectedMetric?.tags ?? []}
              />
            ) : undefined
          }
        />
        <OptOutModal
          key={rowData?.metric.reference}
          isOpen={isOpen}
          onClose={onClose}
          assessmentId={esrsAssessmentId}
          reportingUnitId={companyLevelReportingUnitId}
          metric={selectedMetric}
          isCompany={false}
        />
      </VStack>
      {/* Normal metric sidebar */}
      {!!rowData && (
        <MetricSidebar
          row={rowData}
          metric={rowData?.metric ?? ({} as MetricsTableData['metric'])}
          materialStandardId={companyAssessmentId}
          companyReportingUnit={companyLevelReportingUnitId}
          selectedMetric={selectedMetric}
          esrsAssessmentProjectLeader={projectLeader}
          setSelectedMetric={setSelectedMetric}
          onMenuOpen={onOpen}
          selectedQuarter={selectedQuarter}
          setRowData={setRowData}
          isComments={isComments}
          isGroup={isGroup}
          isMetricDr={isMetricDisclosure}
          companyCurrency={company?.currency ?? ''}
          generatedSummaryStatus={generatedSummaryStatus}
          setGeneratedSummaryStatus={setGeneratedSummaryStatus}
        />
      )}
      {/* MDRM sidebar based on query param */}
      {!rowData && !!searchParams.get('openMdrm') && (
        <Box w="320px" minWidth="320px" overflowY="auto" />
      )}

      <Suspense>
        {selectedExcelMetric && (
          <MetricsExcelUploadModal
            isOpen={isImportOpen}
            onClose={onImportClose}
            metrics={[selectedExcelMetric]}
            disclosureRequirement={disclosureRequirementRef ?? ''}
            businessUnit={company?.name ?? 'Company'}
            areTableMetricsQuarterly={isSelectedMetricQuarterly}
            areTableMetricsYearly={isSelectedMetricYearly}
            companyStandardId={companyAssessmentId}
            companyReportingUnitId={companyLevelReportingUnitId}
          />
        )}
      </Suspense>
      <AnswersApprovalWarningModal
        isOpen={isApprovalWarningModalOpen}
        onClose={onApprovalWarningModalClose}
        onConfirm={() => {
          const metric = aggregatedMetrics
            .filter((met) => met.metric.metricType !== QuestionType_Enum_.Decimal_)
            .find((m) => m.metric.reference === selectedRow)?.metric;

          const answerData = aggregatedMetricsAnswers?.find((a) => a.metricRef === selectedRow);

          if (isEqual(rowData?.metric, metric)) {
            setRowData(undefined);
          } else
            setRowData(
              !!answerData?.reportingUnits?.length || !!answerData?.subsidiaries?.length
                ? { metric: metric as MetricsTableData['metric'], sourceData: answerData }
                : { metric: metric as MetricsTableData['metric'] }
            );
        }}
      />
    </HStack>
  );
};
