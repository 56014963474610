import { DeleteIcon } from '@chakra-ui/icons';
import { Box, Center, HStack, Image, useDisclosure } from '@chakra-ui/react';
import { useUserData } from '@nhost/react';
import { Checkbox, Tag, Tooltip } from 'Atoms';
import {
  GroupAssessmentDocument_,
  GroupAssessmentSubsidiariesFieldsFragment_,
  ScoreSectionsEnum,
  TaxonomyScore,
  useDeleteSubsidiaryMutation,
  useUserCompaniesQuery,
} from 'models';
import { Modal, Table } from 'Molecules';
import { Menu } from 'Molecules/Menu';
import { TaxonomyScoreChart } from 'Organisms';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography } from 'Tokens';
import { CompanyIcon } from 'Tokens/Icons/Data';
import { useToast } from 'utils/hooks';
import { useTranslation } from 'utils/translation';
import { NudgeSubsidiariesModal } from './NudgeSubsidiariesModal';
import { PortfolioCompanyProgress } from 'Features/PortfolioView/PortfolioTaxonomy';

const DeleteSubsidiaryModal = ({
  isOpen,
  onClose,
  id,
}: {
  isOpen: boolean;
  onClose: () => void;
  id: string;
}) => {
  const { t } = useTranslation(['common', 'group']);
  const toast = useToast();
  const [deleteSubsidiary] = useDeleteSubsidiaryMutation();
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="md"
      title={t('group:companies.delete.title')}
      confirmText={t('common:actions.delete')}
      confirmVariant="destructive"
      onConfirm={() => {
        deleteSubsidiary({
          variables: {
            id: id,
          },
          refetchQueries: [GroupAssessmentDocument_],
          awaitRefetchQueries: true,
        })
          .then(() => {
            onClose();
            toast({ text: t('group:companies.delete.toast.success') });
          })
          .catch(() => {
            onClose();
            toast({ text: t('group:companies.delete.toast.failed') });
          });
      }}
    >
      <Typography>{t('group:companies.delete.description')}</Typography>
    </Modal>
  );
};

export const GroupCompaniesTable = ({
  subsidiaries,
  selectedRows,
  setSelectedRows,
  showNudger,
  setShowNudger,
}: {
  subsidiaries?: GroupAssessmentSubsidiariesFieldsFragment_[];
  selectedRows: GroupAssessmentSubsidiariesFieldsFragment_[];
  setSelectedRows: (param: any) => void;
  showNudger: boolean;
  setShowNudger: (param: boolean) => void;
}) => {
  const { t } = useTranslation('group');
  const navigate = useNavigate();
  const [toDelete, setToDelete] = useState<string>('');
  const { isOpen, onClose, onOpen } = useDisclosure();
  const user = useUserData();
  const { data: userCompaniesData } = useUserCompaniesQuery({
    variables: {
      id: user?.id,
    },
    skip: !user,
  });

  return (
    <>
      <Table<GroupAssessmentSubsidiariesFieldsFragment_>
        rowProps={{
          _hover: {
            bg: 'bg.hover',
          },
        }}
        headerPadding="16px 8px"
        columns={[
          {
            header: () => (
              <Checkbox
                isDisabled={!subsidiaries?.length}
                isChecked={selectedRows.length === subsidiaries?.length}
                onChange={(e) =>
                  setSelectedRows(e.currentTarget.checked ? (subsidiaries ?? []) : [])
                }
              />
            ),
            id: 'checkbox',
            cell: ({ row }) => (
              <Checkbox
                isChecked={
                  !!selectedRows.find(
                    (x) => (row.original as GroupAssessmentSubsidiariesFieldsFragment_).id === x.id
                  )
                }
                onChange={(e) => {
                  if (e.currentTarget.checked) {
                    setSelectedRows((cur: GroupAssessmentSubsidiariesFieldsFragment_[]) => [
                      ...cur,
                      row.original as GroupAssessmentSubsidiariesFieldsFragment_,
                    ]);
                  } else
                    setSelectedRows((cur: GroupAssessmentSubsidiariesFieldsFragment_[]) => [
                      ...cur.filter(
                        (item: GroupAssessmentSubsidiariesFieldsFragment_) =>
                          item.id !==
                          (row.original as GroupAssessmentSubsidiariesFieldsFragment_).id
                      ),
                    ]);
                }}
              />
            ),
          },
          {
            header: () => (
              <Typography variant="detailStrong" as="span">
                {t('header.company')}
              </Typography>
            ),
            accessorKey: 'company',
            meta: {
              colSpan: 1,
            },
            cell: ({ row }) => {
              const hasAccessToCompany = userCompaniesData?.data?.companies?.find(
                (c) => c.company?.id === row.original?.company?.id
              );
              return (
                <HStack
                  onClick={() => hasAccessToCompany && navigate(`/${row.original?.company?.id}`)}
                  cursor={hasAccessToCompany ? 'pointer' : ''}
                >
                  <Tooltip label={hasAccessToCompany ? t('common:goToCompany') : ''}>
                    <HStack>
                      {row.original.company?.logoUrl ? (
                        <Image
                          src={row.original.company?.logoUrl}
                          w="28px"
                          h="28px"
                          borderRadius="6px"
                        />
                      ) : (
                        <Center w="28px" h="28px" borderRadius="6px" bg="bg.accent">
                          <CompanyIcon boxSize="16px" color="white" />
                        </Center>
                      )}
                      <Typography variant="body">{row.original.company?.name}</Typography>
                    </HStack>
                  </Tooltip>
                </HStack>
              );
            },
          },
          {
            header: () => (
              <Typography variant="detailStrong" as="span">
                {t('header.status')}
              </Typography>
            ),
            accessorKey: 'status',
            meta: {
              colSpan: 1,
            },
            cell: ({ row }) => {
              if (row.original.company?.isGroupOwner) {
                return (
                  <Tag
                    variant={row.original.sharedCompanyAssessment?.isLocked ? 'success' : 'info'}
                  >
                    {row.original.sharedCompanyAssessment?.isLocked
                      ? t('assessment.status.complete')
                      : t('assessment.status.progress')}
                  </Tag>
                );
              }
              return (
                <PortfolioCompanyProgress
                  progress={{
                    ...(row.original.sharedCompanyAssessment?.cachedResult?.progress ?? {
                      financials: 0,
                      screening: 0,
                    }),
                    isLocked: row.original.sharedCompanyAssessment?.isLocked ?? false,
                  }}
                />
              );
            },
          },
          {
            header: () => (
              <Typography variant="detailStrong" as="span">
                {t('header.turnover')}
              </Typography>
            ),
            accessorKey: 'turnover',
            meta: {
              colSpan: 1,
            },
            cell: ({ row }) => (
              <HStack>
                <TaxonomyScoreChart
                  size="sm"
                  score={
                    row.original.sharedCompanyAssessment?.cachedResult?.score.revenue ??
                    ({} as TaxonomyScore)
                  }
                  title={ScoreSectionsEnum.revenue}
                  withDetails={true}
                />
              </HStack>
            ),
          },
          {
            header: () => (
              <Typography variant="detailStrong" as="span">
                {t('header.capex')}
              </Typography>
            ),
            accessorKey: 'capex',
            meta: {
              colSpan: 1,
            },
            cell: ({ row }) => (
              <HStack>
                <TaxonomyScoreChart
                  size="sm"
                  score={
                    row.original.sharedCompanyAssessment?.cachedResult?.score.capex ??
                    ({} as TaxonomyScore)
                  }
                  title={ScoreSectionsEnum.capex}
                  withDetails={true}
                />
              </HStack>
            ),
          },
          {
            header: () => (
              <Typography variant="detailStrong" as="span">
                {t('header.opex')}
              </Typography>
            ),
            accessorKey: 'opex',
            meta: {
              colSpan: 1,
            },
            cell: ({ row }) => (
              <HStack>
                <TaxonomyScoreChart
                  size="sm"
                  score={
                    row.original.sharedCompanyAssessment?.cachedResult?.score.opex ??
                    ({} as TaxonomyScore)
                  }
                  title={ScoreSectionsEnum.opex}
                  withDetails={true}
                />
              </HStack>
            ),
          },
          {
            header: '',
            accessorKey: 'actions',
            meta: {
              colSpan: 1,
            },
            cell: ({ row }) => (
              <Box textAlign="right">
                <Menu
                  sections={[
                    {
                      actions: [
                        {
                          id: 'delete',
                          title: t('common:actions.delete'),
                          variant: 'destructive',
                          onClick: () => {
                            setToDelete(row.original.id);
                            onOpen();
                          },
                          leftElement: <DeleteIcon color="inherit" />,
                        },
                      ],
                    },
                  ]}
                />
              </Box>
            ),
          },
        ]}
        data={subsidiaries ?? []}
      />
      <DeleteSubsidiaryModal id={toDelete} isOpen={isOpen} onClose={onClose} />
      <NudgeSubsidiariesModal
        subsidiaries={selectedRows}
        isOpen={showNudger}
        onClose={() => {
          setShowNudger(false);
          setSelectedRows([]);
        }}
      />
    </>
  );
};
