import {
  GetAnswersForMetricsOnCompanyLevelQuery_,
  GetAnswersForMetricsOnGroupLevelQuery_,
  GetCompanyLevelMdrmAnswersQuery_,
} from 'models';
import {
  getAggregatedMetricAnswers,
  getMetricAnswers,
  MetricsTableData,
  MaybeHasChild,
  MetricType,
  MdrmTypeEnum,
} from '../DisclosureRequirements';
import { AssessableMetrics } from '../DisclosureRequirements/Metrics/Metrics';
import { ReportChildMetrics, ReportMetric } from './Report.types';

export const getMetricResult = (data: {
  metric: AssessableMetrics[number];
  nestedMetric: MetricsTableData;
  isGroup: boolean;
  materialStandardId: string;
  reportingUnitsAnswers?: GetAnswersForMetricsOnCompanyLevelQuery_;
  groupAnswers?: GetAnswersForMetricsOnGroupLevelQuery_;
  standardRef?: string;
}) => {
  const {
    metric,
    nestedMetric,
    isGroup,
    materialStandardId,
    reportingUnitsAnswers,
    groupAnswers,
    standardRef,
  } = data;

  const subsidiaries = groupAnswers?.EsrsAssessment_by_pk?.subsidiaryAssessments ?? [];
  const reportingUnits = reportingUnitsAnswers?.EsrsAssessment_by_pk?.reportingUnits ?? [];

  const answers = getMetricAnswers({
    metric,
    companyAnswersData: reportingUnits,
    groupAnswersData: subsidiaries,
    isGroup,
    standardRef,
    materialStandardId,
  });

  const result = getAggregatedMetricAnswers(nestedMetric, isGroup, answers);
  return result;
};

type ReportMdrmAnswerType = GetCompanyLevelMdrmAnswersQuery_['esrs_DatapointMdrm'][number];
export type ReportMdrmMetricAnswers = {
  metric: MetricType;
  answers: {
    metricUsed: ReportMdrmAnswerType | undefined;
    methodology: ReportMdrmAnswerType | undefined;
    validation: ReportMdrmAnswerType | undefined;
    entitySpecific: ReportMdrmAnswerType | undefined;
  };
};

// Not used anymore, kept in case needed again later
const getReportFullMetricWithChildren = (metric: ReportMetric) => {
  const childrenMetricRefs = [metric];

  const recurseChildren = (childrenMetrics: MaybeHasChild['childrenMetrics']) => {
    if (!!childrenMetrics?.length) {
      childrenMetrics.forEach((child) => {
        if (!!child.childMetric) {
          childrenMetricRefs.push(child.childMetric as ReportChildMetrics[number]['childMetric']);
          recurseChildren(child?.childMetric.childrenMetrics as MaybeHasChild['childrenMetrics']);
        }
      });
    }
  };

  if (metric?.childrenMetrics) {
    recurseChildren(metric?.childrenMetrics as MaybeHasChild['childrenMetrics']);
  }
  return childrenMetricRefs.filter((m) => m !== undefined) ?? [];
};

export const getMetricMdrmAnswers = (
  metric: ReportMetric,
  mdrmAnswers?: GetCompanyLevelMdrmAnswersQuery_['esrs_DatapointMdrm']
): ReportMdrmMetricAnswers => {
  const answers = mdrmAnswers?.filter(
    (mdrmA) => mdrmA.datapoint.answer.metricRef === metric?.reference
  );
  const metricAnswers = {
    metric: metric as MetricType,
    answers: {
      metricUsed: answers?.find((a) => a.mdrmRef === MdrmTypeEnum.metricUsed),
      methodology: answers?.find((a) => a.mdrmRef === MdrmTypeEnum.methodology),
      validation: answers?.find((a) => a.mdrmRef === MdrmTypeEnum.validation),
      entitySpecific: answers?.find((a) => a.mdrmRef === MdrmTypeEnum.entitySpecific),
    },
  };
  return metricAnswers;
};
