import { Box, Skeleton, VStack } from '@chakra-ui/react';
import { FinancialFilter } from '../Header/FinancialsHeader.hooks.ts';
import { useGetFilteredFinancialsData } from './FinancialTables.hooks.ts';
import { CompanyNotEligibleTable } from './CompanyNotEligibleTable.tsx';
import { FinancialSummary } from './SummaryTable.tsx';
import { TotalComparisonTable } from './TotalComparisonTable.tsx';
import { BusinessUnitFinancialTable } from './BusinessUnitFinancialTable';
import { GroupFinancials } from './GroupFinancials.tsx';
import { CompanyFinancialResults } from 'Features/Financials/Financials.hooks.ts';

export const FilteredFinancials = () => {
  const { filter, filteredFinancials, loading, sortedItems } = useGetFilteredFinancialsData();

  if (filter === FinancialFilter.byId && filteredFinancials) {
    return (
      <Skeleton isLoaded={!loading}>
        <BusinessUnitFinancialTable
          key={filteredFinancials?.id}
          businessUnit={filteredFinancials}
        />
      </Skeleton>
    );
  }

  return (
    <Skeleton isLoaded={!loading} width="100%" padding="16px" borderRadius="16px">
      <VStack spacing="16px" paddingBottom="16px" width="100%" alignItems="flex-start">
        {sortedItems.map((item) =>
          item.isGroup ? (
            <GroupFinancials
              key={item.id}
              reportingGroupId={item.id.replace('rg-', '')}
              bg="solid"
            />
          ) : (
            <BusinessUnitFinancialTable
              key={item.id}
              businessUnit={item.data as CompanyFinancialResults['businessUnits'][number]}
            />
          )
        )}
        <Box width="100%">
          <CompanyNotEligibleTable />
        </Box>
        <Box width="100%">
          <FinancialSummary />
        </Box>
        <Box width="100%">
          <TotalComparisonTable />
        </Box>
      </VStack>
    </Skeleton>
  );
};
