import { HStack } from '@chakra-ui/react';
import { useCurrentCompany } from 'utils/hooks';
import { CompanyStructure, NodeType } from 'Features/CompanyStructure';
import { CompanyAssessment, useReportingGroupsQuery } from 'models';
import { useSearchParams } from 'react-router-dom';
import { useMemo } from 'react';
import { BusinessUnitsSortingType } from 'containers/Assessments/pieces/Assessment.hooks';
import { FinancialsTablesPage } from './FinancialsTables/FinancialsTablesPage.tsx';

export const Financials = ({
  cAssessment,
  businessUnitsSorting,
}: {
  cAssessment: CompanyAssessment;
  businessUnitsSorting: BusinessUnitsSortingType;
}) => {
  const { company } = useCurrentCompany();

  const [searchParams, setSearchParams] = useSearchParams();

  const { data } = useReportingGroupsQuery({
    variables: {
      cAssessmentId: cAssessment?.id,
    },
    skip: !cAssessment?.id,
  });

  const reportingGroups = data?.reportingGroups ?? [];

  const handleSelection = (selected: { nodeType: NodeType; nodeId: string }) => {
    setSearchParams(selected);
  };

  return (
    <HStack spacing="0px" alignItems="stretch" justifyContent="space-between" width="100%">
      {company && (
        <CompanyStructure
          reportingGroups={reportingGroups}
          bAssessments={cAssessment?.bAssessments ?? []}
          company={company}
          cAssessment={cAssessment}
          selectedNode={searchParams.get('nodeId') ?? 'company'}
          onSelect={handleSelection}
          selectableNodes={[NodeType.businessUnit, NodeType.company]}
          businessUnitsSorting={businessUnitsSorting}
        />
      )}
      <FinancialsTablesPage />
    </HStack>
  );
};
