import React, { Fragment, useMemo, useRef, useState } from 'react';
import {
  BreakdownStatus,
  MetricTagDetailForSubsidiary,
  TableMetricData,
  TagStatus,
} from './DataCollection.d';
import {
  Box,
  HStack,
  IconButton,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  useColorMode,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { ProgressBar, Tag, Tooltip, TruncatableText } from 'Atoms';
import { ArrowUpRightIcon, ChevronDownIcon, CornerDownRightIcon } from 'Tokens/Icons/Direction';
import { Typography, colors } from 'Tokens';
import { MetricConfigModal } from './MetricConfigModal/MetricConfigModal';
import { MetricConfigModalParent } from './MetricConfigModal/MetricConfigModalParent';
import { WarningIcon } from 'Tokens/Icons/Status';
import { hasDataCollectionGroup } from './DataCollection.hooks';
import { useParams } from 'react-router-dom';
import { CompanyIcon } from '../../../../Tokens/Icons/Data';

const getBreakdownStatus = (
  row: TableMetricData | undefined,
  companyStandardId: string,
  parentStandardId: string,
  isGroupCompany: boolean,
  metricHasSomeDataCollectionGroup: boolean
): TagStatus => {
  const parentMetric = row?.parentMetric;
  const allTags = row?.adminPanelTags;
  const requiredTags = allTags?.filter((tag) => !tag.isOptional);
  const materialMetric = row?.materialMetrics?.find(
    (mm) => mm.materialStandardId === companyStandardId
  );
  const addedTags =
    isGroupCompany && !metricHasSomeDataCollectionGroup
      ? materialMetric?.materialMetricTags?.filter(
          (tag) => !requiredTags?.find((reqTag) => reqTag.type === tag.tagType)
        )
      : materialMetric?.materialMetricTags?.filter((tag) => tag.materialTagValues.length > 0);

  const required = requiredTags?.length;
  const added = addedTags?.length;
  const available = allTags?.filter(
    (tag) => tag.isOptional && !addedTags?.find((y) => y.tagType === tag.type)
  ).length;

  const parentHasTags =
    !!parentMetric?.adminPanelTags.length ||
    !!parentMetric?.materialMetrics.find((mm) => mm.materialStandardId === companyStandardId)
      ?.materialMetricTags.length ||
    !!parentMetric?.materialMetrics.find((mm) => mm.materialStandardId === parentStandardId)
      ?.materialMetricTags.length;

  if (row?.isChild && parentHasTags) {
    return getBreakdownStatus(
      parentMetric as TableMetricData,
      companyStandardId,
      parentStandardId,
      isGroupCompany,
      metricHasSomeDataCollectionGroup
    );
  } else if (added && required && added < required) {
    return { state: BreakdownStatus.partiallyConfigured, number: added / required };
  } else if (added) {
    return { state: BreakdownStatus.added, number: added };
  } else if (required) {
    return { state: BreakdownStatus.required, number: required };
  } else if (available) {
    return { state: BreakdownStatus.available, number: 0 };
  }
  return { state: BreakdownStatus.notApplicable, number: 0 };
};

export const BreakDownTag = React.memo(
  ({
    metric,
    isGroupCompany,
    companyStandardId,
    parentStandardId,
    tagDetailForSubsidiaries,
    hasParentCompany,
  }: {
    metric: TableMetricData;
    isGroupCompany: boolean;
    companyStandardId: string;
    parentStandardId: string;
    tagDetailForSubsidiaries?: { [key: string]: MetricTagDetailForSubsidiary };
    hasParentCompany: boolean;
  }) => {
    const { disclosureRequirementRef, standardRef } = useParams();
    const metricHasSomeDataCollectionGroup = useMemo(
      () => hasDataCollectionGroup(metric),
      [metric]
    );
    const BREAKDOWN_STATES = useMemo(() => {
      return {
        required: {
          text: 'Required',
          variant: !isGroupCompany || metricHasSomeDataCollectionGroup ? 'warning' : 'default',
        },
        added: {
          text: 'Configured',
          variant: 'info',
        },
        partiallyConfigured: {
          text: 'Configured',
          variant: 'warning',
        },
        requested: {
          text: 'Requested',
          variant: 'selected',
        },
        available: {
          text: 'Optional',
          variant: 'undefined',
        },
        notApplicable: {
          text: 'N/A',
          variant: 'default',
        },
      };
    }, [isGroupCompany]);

    const materialMetric = useMemo(
      () => metric.materialMetrics.find((mm) => mm.materialStandardId === companyStandardId),
      [metric, companyStandardId]
    );

    const addedBreakdowns = useMemo(
      () =>
        materialMetric?.materialMetricTags.filter((tag) => tag.materialTagValues.length > 0) ?? [],
      [materialMetric, companyStandardId]
    );
    const requiredBreakdowns = useMemo(
      () => metric.adminPanelTags.filter((tag) => !tag.isOptional) ?? [],
      [metric]
    );
    const remainingBreakdowns = useMemo(
      () =>
        requiredBreakdowns.filter(
          (required) => !addedBreakdowns.find((added) => added.tagType === required.type)
        ),
      [addedBreakdowns, requiredBreakdowns]
    );

    const parentMetricHasTags = useMemo(
      () =>
        !!metric?.parentMetric?.adminPanelTags.length ||
        !!metric?.parentMetric?.materialMetrics.find(
          (mm) => mm.materialStandardId === companyStandardId
        )?.materialMetricTags.length ||
        !!metric?.parentMetric?.materialMetrics.find(
          (mm) => mm.materialStandardId === parentStandardId
        )?.materialMetricTags.length,
      [metric]
    );

    const status = useMemo(
      () =>
        getBreakdownStatus(
          metric,
          companyStandardId,
          parentStandardId,
          isGroupCompany,
          metricHasSomeDataCollectionGroup
        ),
      [metric, companyStandardId, parentStandardId, isGroupCompany]
    );

    const parentMetricStatus = useMemo(
      () =>
        getBreakdownStatus(
          metric.parentMetric as TableMetricData,
          companyStandardId,
          parentStandardId,
          isGroupCompany,
          metricHasSomeDataCollectionGroup
        ),
      [metric.parentMetric, companyStandardId, parentStandardId, isGroupCompany]
    );

    const addedTagsRatio = useMemo(() => {
      const required = requiredBreakdowns?.length ?? 0;
      const added = required - (remainingBreakdowns?.length ?? 0);
      return `${added}/${required}`;
    }, [requiredBreakdowns, remainingBreakdowns]);

    const { isOpen, onOpen, onClose } = useDisclosure();

    const notApplicable = useMemo(() => status.state === BreakdownStatus.notApplicable, [status]);

    // NA tag
    if (notApplicable) return <Tag disabled>{BREAKDOWN_STATES[status.state].text}</Tag>;

    // Inherited tag
    if (metric.isChild && parentMetricHasTags) {
      return (
        <Tooltip label="Inherited parent metric configurations (see above)">
          <HStack spacing="2px">
            <CornerDownRightIcon color="text.hint" />
            <Tag disabled>Inherited</Tag>
          </HStack>
        </Tooltip>
      );
    }

    // Tag text
    const breakdownText = useMemo(() => {
      let tagCountString = '';
      if (
        status.state === BreakdownStatus.added ||
        status.state === BreakdownStatus.partiallyConfigured
      ) {
        tagCountString = `(${addedTagsRatio})`;
      } else if (
        status.state === BreakdownStatus.available ||
        status.state === BreakdownStatus.notApplicable
      ) {
        tagCountString = '';
      } else {
        tagCountString = `(${status.number})`;
      }
      return `${BREAKDOWN_STATES[status.state].text} ${tagCountString}`;
    }, [status, addedTagsRatio]);

    if (
      isGroupCompany &&
      !metricHasSomeDataCollectionGroup &&
      status.state !== BreakdownStatus.added &&
      status.state !== BreakdownStatus.available
    ) {
      const progress = Math.floor(
        ((tagDetailForSubsidiaries?.[metric.reference]?.['configuredCount'] ?? 0) /
          (tagDetailForSubsidiaries?.[metric.reference]?.['subsidiaryDetail'].length ?? 1)) *
          100
      );
      return (
        <Popover trigger="hover">
          <PopoverTrigger>
            <Tag disabled>Required</Tag>
          </PopoverTrigger>
          <PopoverContent
            w={'326px'}
            border="none"
            boxShadow=" 0px 0px 24px -2px #0F0F2E1F"
            borderRadius="10px"
          >
            <PopoverBody p="16px">
              <VStack
                alignItems="start"
                w="100%"
                gap="16px"
                justifyContent="start"
                p={0}
                spacing="16px"
              >
                <VStack alignItems="start" w="100%" gap="8px">
                  <HStack justifyContent="space-between" w="100%">
                    <Typography variant="bodyStrong">Disaggregation setup progress</Typography>
                    <Typography variant="h4">{progress}%</Typography>
                  </HStack>
                  <ProgressBar completed={progress} />
                </VStack>
                {tagDetailForSubsidiaries?.[metric?.reference]?.subsidiaryDetail?.map((sub) => (
                  <HStack spacing="16px" width="100%" justifyContent="space-between">
                    <HStack gap="6px" width={130}>
                      <CompanyIcon />
                      <TruncatableText variant="body" text={sub?.companyName} />
                    </HStack>
                    <HStack gap="6px">
                      <Tag variant={sub.variant} size={'s'}>
                        {sub.status.text} {sub.status.ratio}
                      </Tag>
                      <IconButton
                        aria-label="click"
                        size="sm"
                        variant="ghost"
                        icon={<ArrowUpRightIcon />}
                        onClick={() =>
                          window.open(
                            `/${sub.companyId}/esrs/${sub.assessmentId}/settings/data-collection/${standardRef}/disclosure-requirement/${disclosureRequirementRef}`
                          )
                        }
                      />
                    </HStack>
                  </HStack>
                ))}
              </VStack>
            </PopoverBody>
          </PopoverContent>
        </Popover>
      );
    }
    // Tag with modal trigger
    return (
      <HStack spacing="4px">
        <Tag
          opacity={
            metric.isChild && parentMetricStatus.state !== BreakdownStatus.notApplicable ? 0.3 : 1
          }
          variant={BREAKDOWN_STATES[status.state].variant}
          clickable
          onClick={onOpen}
          rightIcon={<ChevronDownIcon color={'inherit'} />}
          title={breakdownText}
          truncatable
        />

        {isGroupCompany ? (
          <MetricConfigModalParent
            companyStandardId={companyStandardId}
            isOpen={isOpen}
            onClose={onClose}
            parentStandardId={parentStandardId}
            selectedMetricData={metric}
          />
        ) : (
          <MetricConfigModal
            companyStandardId={companyStandardId}
            isOpen={isOpen}
            onClose={onClose}
            parentStandardId={parentStandardId}
            selectedMetricData={metric}
            hasParentCompany={hasParentCompany}
          />
        )}
      </HStack>
    );
  }
);

export const MetricNameWithTag = React.memo(
  ({
    name,
    parentRowName,
    tags,
    unconfiguredTags,
    configuredTagsWithValues,
    rowRef,
    isNarrative,
    textColor,
    isReport,
  }: {
    name: string;
    parentRowName?: string;
    tags: string[];
    unconfiguredTags?: string[];
    configuredTagsWithValues?: { type: string; values: string[] }[];
    rowRef: React.MutableRefObject<HTMLDivElement>;
    isNarrative?: boolean;
    textColor?: string;
    isReport?: boolean;
  }) => {
    const [showTooltip, setShowTooltip] = useState(false);
    const { colorMode } = useColorMode();
    const isDarkMode = useMemo(() => colorMode === 'dark', [colorMode]);
    const color = useMemo(() => (isDarkMode ? '_dark' : 'default'), [isDarkMode]);
    const componentRef = useRef<HTMLDivElement>(null);
    const handleShowTooltip = () => {
      if (componentRef.current && rowRef.current) {
        if (componentRef.current.scrollWidth >= rowRef.current.scrollWidth) {
          setShowTooltip(true);
        } else setShowTooltip(false);
      }
    };

    const tagStyle = {
      borderRadius: '4px',
      border: '1px solid',
      borderColor: colors['border.decorative'][color],
      padding: '2px 6px 2px 4px',
    };

    const unconfiguredTagStyle = {
      borderRadius: '4px',
      padding: '2px 4px 2px 4px',
    };

    const getValuesString = (tagValues: string[]) => {
      if (tagValues.length > 3) {
        return `${tagValues.slice(0, 3).join(', ')} and more`;
      } else {
        return tagValues.join(', ');
      }
    };

    const getTooltipText = (tagType: string) => {
      const needsConfiguration = unconfiguredTags?.includes(tagType) ?? false;
      const configuredTags = configuredTagsWithValues?.find((t) => t.type === tagType)?.values;

      if (needsConfiguration || !configuredTags) {
        return 'Breakdown not configured (see the Breakdown column to the right)';
      } else {
        return `Broken down by ${tagType}: ${getValuesString(configuredTags)} (for details see the Breakdown column to the right)`;
      }
    };

    return (
      <Box ref={componentRef} w="fit-content">
        {showTooltip && !isNarrative ? (
          <Tooltip
            isDisabled={!showTooltip}
            maxW="400px"
            width="100%"
            placement="bottom-start"
            label={`${name} ${tags.length ? 'by ' + tags.join(', ') : ''}${
              parentRowName ? ` (${parentRowName})` : ''
            }`}
          >
            <VStack alignItems="start" spacing={0}>
              <HStack onMouseEnter={handleShowTooltip}>
                <Typography
                  noOfLines={isNarrative ? undefined : 1}
                  variant="body"
                  color={textColor}
                >
                  {name}
                  {tags.map((tag, index) => {
                    const needsConfiguration = unconfiguredTags?.includes(tag) ?? false;
                    const tooltipLabel = getTooltipText(tag);

                    return (
                      <Fragment key={index}>
                        {index > 0 && ', '}
                        <Tooltip label={tooltipLabel}>
                          <Typography
                            display="inline-flex"
                            variant="detailStrong"
                            style={needsConfiguration ? unconfiguredTagStyle : tagStyle}
                            as="span"
                            verticalAlign="middle"
                            alignItems="center"
                            bg={needsConfiguration ? 'bg.warning' : 'unset'}
                            color={needsConfiguration ? 'text.warning' : 'unset'}
                            ml="2px"
                          >
                            {needsConfiguration && (
                              <WarningIcon color="inherit" boxSize="14px" mr="4px" />
                            )}
                            by {tag}
                          </Typography>
                        </Tooltip>
                      </Fragment>
                    );
                  })}
                  {parentRowName && !isReport ? (
                    <Typography display="inline" variant="body" color="text.hint" as="span">
                      ({parentRowName})
                    </Typography>
                  ) : (
                    ''
                  )}
                </Typography>
              </HStack>
            </VStack>
          </Tooltip>
        ) : (
          <VStack alignItems="start" spacing={0}>
            <HStack onMouseEnter={handleShowTooltip}>
              <Typography noOfLines={isNarrative ? undefined : 1} variant="body" color={textColor}>
                {`${name}`}{' '}
                {tags.map((tag, index) => {
                  const needsConfiguration = unconfiguredTags?.includes(tag) ?? false;
                  const tooltipLabel = getTooltipText(tag);

                  return (
                    <Fragment key={index}>
                      {index > 0 && ', '}
                      <Tooltip label={tooltipLabel}>
                        <Typography
                          display="inline-flex"
                          variant="detailStrong"
                          style={needsConfiguration ? unconfiguredTagStyle : tagStyle}
                          as="span"
                          verticalAlign="middle"
                          alignItems="center"
                          bg={needsConfiguration ? 'bg.warning' : 'unset'}
                          color={needsConfiguration ? 'text.warning' : 'unset'}
                          ml="2px"
                        >
                          {needsConfiguration && (
                            <WarningIcon color="inherit" boxSize="14px" mr="4px" />
                          )}
                          by {tag}
                        </Typography>
                      </Tooltip>
                    </Fragment>
                  );
                })}
                {parentRowName && !isReport ? (
                  <Typography display="inline" variant="body" color="text.hint" as="span">
                    ({parentRowName})
                  </Typography>
                ) : (
                  ''
                )}
              </Typography>
            </HStack>
          </VStack>
        )}
      </Box>
    );
  }
);
