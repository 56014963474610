import { VStack, HStack } from '@chakra-ui/react';
import { NegativeNumbersWarningBox } from 'Features/Financials/FinancialsTables/Header/NegativeNumbersWarningBox.tsx';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from 'Tokens/index.ts';
import { FinancialsCSVUploadButton } from './FinancialsCSVUploadButton.tsx';
import { FinancialsHelpInfoBox } from './FinancialsHelpInfoBox.tsx';
import { FinancialsViewForTag } from './FinancialsViewForTag.tsx';
import { IconButton } from 'Atoms/Buttons/Buttons.tsx';
import { InfoIcon } from 'Tokens/Icons/Status.tsx';

export const FinancialsTablesPageHeader = () => {
  const { t } = useTranslation();
  const [shouldShowHelp, setShouldShowHelp] = useState(true);

  return (
    <VStack alignItems="stretch" gap="32px" width="100%">
      <VStack alignItems="start" gap="0px">
        <HStack width="100%" pb="8px" justifyContent="space-between">
          <HStack justifyContent="start" height="fit-content">
            <VStack spacing="0px" alignItems="flex-start">
              <Typography variant={'h2'}>{t('common:assessment.financials')}</Typography>
            </VStack>
          </HStack>
          <HStack height="fit-content">
            <IconButton
              variant="ghost"
              icon={<InfoIcon color="inherit" />}
              aria-label="info"
              onClick={() => setShouldShowHelp(!shouldShowHelp)}
              tooltipLabel={t('common:actions.help')}
            />
          </HStack>
        </HStack>
      </VStack>
      <FinancialsHelpInfoBox
        onClose={() => {
          setShouldShowHelp(false);
        }}
        shouldShowHelp={shouldShowHelp}
      />
      <HStack width="100%" justifyContent="space-between">
        <FinancialsViewForTag />
        <FinancialsCSVUploadButton />
      </HStack>
      <NegativeNumbersWarningBox mb="8px" />
    </VStack>
  );
};
